import React from "react";
import { useNavigate } from "react-router-dom";

const ResaItem = ({ name, id }) => {
  const navigate = useNavigate();
  return (
    <div className="resa-item" onClick={() => navigate(`/resa/${id}`)}>
      <div className="fs-4 fw-bold">{name}</div>
    </div>
  );
};

export default ResaItem;
