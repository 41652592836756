import { getAppId } from "../../api/getAppId";

export const setHeader = () => {


    let appId = getAppId();

    let header = "";

    switch(appId)
    {
      case 1:
        header = "SävsjöAppen";
        break;
      case 2:
        header = "VetlandaAppen";
        break;
      case 3:
        header = "NässjöAppen";
        break;
      case 5:
        header = "EksjöAppen";
        break;          
      case 30:
        header = "HagforsAppen";
        break;
      case 36:
        header = "ÖrkelljungaAppen";
        break;
      case 35:
        header = "LjungbyAppen";
        break;
      case 16:
        header = "AlvestaAppen";
        break;                 

    }

    return header;
};
