import axios from "axios";
import { getAppId } from "./getAppId";

export const getFamilyNews = async (setFamilyNewsData, setLoading) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://cdn.lokal.app/api/adsforweb?c_id=-8&app_id=" + getAppId(),
  })
    .then((res) => {
      setFamilyNewsData(res.data);
      setLoading(false);
      console.log(res.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getFamilyNewsDetails = async (
  setFamilyNewsDetails,
  setLoading,
  id
) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: `https://cdn.lokal.app/api/ad/${id}`,
  })
    .then((res) => {
      setFamilyNewsDetails(res.data);
      setLoading(false);
      console.log(res.data);
    })
    .catch((e) => {
      console.log(e);
    });
};
