import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const ImageThmbSlider = ({ currentIndex, setCurrentIndex, slides, id, className }) => {
  const next = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const prev = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  return (
    <Carousel
      showThumbs={false}
      showArrows={false}
      selectedItem={currentIndex}
      showStatus={true}
      interval={4000}
      autoPlay={true}
      infiniteLoop={true}
      statusFormatter={(slides, total) => {
        return `${slides} av ${total}`;
      }}
      className="carousel ms-auto"
    >
      {slides.map((media) => (
        <img
          src={`https://cdn.lokal.app/uploads/${media.src_thmb}`}
          alt=""
          className={`rounded ${className}`}
          key={id}
        />
      ))}
    </Carousel>
  );
};

export default ImageThmbSlider;
