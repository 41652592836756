import React from "react";

const ResaDetailsStops = ({ stopsName, stopsTime }) => {
  return (
    <div className="stops d-flex gap-5">
      <div className="stops-name fs-5">{stopsTime}</div>
      <div className="stops-time fs-5">{stopsName}</div>
    </div>
  );
};

export default ResaDetailsStops;
