import React from "react";
import residenceImg from "../../../../public/images/residence.png";
import Map from "../../maps/Map";
import ResidenceForSaleLogic from "./logic/ResidenceForSaleLogic";
import "./ResidenceForSale.css";
import ResidenceForSaleItem from "./ResidenceForSaleItem";
import Loading from "../../UI/Loading";
import PageHederImage from "../../UI/HeaderImage";

// FIX LIST KEY !!!!!!

const ResidenceForSale = () => {
  const { residenceForSaleData, longitude, latitude, loading } = ResidenceForSaleLogic();

  return (
    <div className="residence-for-sale d-flex flex-column gap-3">
      <PageHederImage image={residenceImg} text="Bostäder till salu" />

      <div className="karta fs-1">
        <Map data={residenceForSaleData} long={longitude} lat={latitude} height="500px" fontsize={"100px"} />
      </div>
      {loading ? (
        <Loading />
      ) : (
        residenceForSaleData?.map((item, index) => (
          <div key={item.id}>
            <ResidenceForSaleItem
              img={item.img}
              type={item.type}
              price={item.price <= 0 ? "Utropspris Saknas" : item.price + " kr"}
              adress={item.address}
              place={item.place}
              published={item.published.slice(0, 16)}
              url={item.url}
            />
            <hr className="border border-1 border-dark" key={index} />
          </div>
        ))
      )}
    </div>
  );
};

export default ResidenceForSale;
