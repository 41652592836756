import React from 'react'
import { useNavigate } from 'react-router-dom';
import associationImg from "../../../../public/images/association.png";
import AdvertiserAssociationAd from '../../advertiser/AdvertiserAssociationAd';
import AdvertiserNameLogo from '../../advertiser/AdvertiserNameLogo';
import PageHederImage from '../../UI/HeaderImage';
import Image from '../../UI/Image';
import Loading from '../../UI/Loading';
import HomeLogic from '../home/logic/HomeLogic';

const AssociationLife = () => {

  const { firstNews, firstCompanyAd, firstAssociationAd, feed, loading } =
    HomeLogic();

  const navigate = useNavigate();

  const navigateOnClick = (id) => {
    navigate(`/ad/${id}`);
  }

  return (

    <div className="">
      <PageHederImage image={associationImg} text="Föreningsinfo" />
   
    
    {loading ? (
        <Loading />
      ) : (

        <>
      {feed?.filter(x => x.type == 'association_ad').map((item) => (
          
          <div
      className="grayBackground p-3 mb-3"
      onClick={() => navigateOnClick(id)}
      key={"ad" + item.id}
    >

      <div className="row">
        <div className="col-9">
          <div className={`fs-3 fw-bold lh-sm`}>
            {item.title}
          </div>
          <div
            className={`fs-5 lines-4 lh-sm`}
            dangerouslySetInnerHTML={{ __html: item.body }}
          ></div>
        </div>
        <div className="col-3 img-max">
          <Image medias={item.medias} type="thmb" / >
        </div>
      </div>
      <div className='mt-3'>
        <AdvertiserNameLogo data={item.customer} />
      </div>
    </div>
      ))}
      </>
      )}
      


      </div>

  );

}

export default AssociationLife