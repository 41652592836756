import axios from "axios";
import { getAppId } from "./getAppId";

export const getEvents = async (setEventsData, setLoading) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/eventsforapp?app_id=" + getAppId(),
  })
    .then((res) => {
      setEventsData(res.data);
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getEventDetails = async (id, setEventDetailsData) => {
  return await axios({
    method: "get",
    url: `https://api.lokal.app/api/event/${id}`,
  })
    .then((res) => {
      setEventDetailsData(res.data);
    })
    .catch((e) => {
      console.log(e);
    });
};
