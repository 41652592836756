import axios from "axios";
import { getAppId } from "./getAppId";

export const getHomeFeed = async (
  setFirstNews,
  setFirstCompanyAd,
  setFirstAssociationAd,
  setFeed,
  setLoading
) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/adsforweb?type=feed&app_id=" + getAppId() ,
  })
    .then((res) => {
      const firstNews = res.data.first_newses;
      const firstCompanyAd = res.data.first_company_ads;
      const firstAssociationAd = res.data.first_association_ads;
      const feed = res.data.feed;
      setFirstNews(firstNews);
      setFirstCompanyAd(firstCompanyAd);
      setFirstAssociationAd(firstAssociationAd);
      setFeed(feed);
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
    });
};
