import React from "react";

const AdvertiserBody = ({ data, underpage }) => {
  let foundPage = data.pages?.find((page) => page.typ === "textsida");
  return (
    <div className="advertiser-details-body d-flex flex-column gap-3">
      {data.profile_img != null && (
        <img
          src={`https://cdn.lokal.app/uploads/${data.profile_img}`}
          alt=""
          className="advertiser-details-body-img"
        />
      )}
      {foundPage && <div className="fs-1 fw-bold">{foundPage?.title}</div>}
      <div
        className="fs-5"
        dangerouslySetInnerHTML={
          underpage === true
            ? {
                __html: foundPage?.txt,
              }
            : { __html: data.profile_txt }
        }
      ></div>
    </div>
  );
};

export default AdvertiserBody;
