import React from "react";
import { useNavigate } from "react-router-dom";
import "./externalAd.css";
import { getExternalAd } from "../../../../api/externalAd";

const ExternalAd = (params) => {

  const navigate = useNavigate();

  const [ htmlData, sethtmlData] = React.useState();
  const [ loading, setLoading ] = React.useState();

  React.useEffect(() => {
    getExternalAd(sethtmlData, setLoading, params.format);
  }, [navigate]);

  return (
    <>
      { htmlData ? (<div className="externalAd">
      <div class="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
      <div
          className=""
          dangerouslySetInnerHTML={{ __html: htmlData }}
        ></div>
      </div>) : ( null )
      }
    </>
  );

};

export default ExternalAd;
