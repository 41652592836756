import axios from "axios";
import React, { useEffect } from "react";
import { getAppId } from "../../../../api/getAppId";



const PhotoForm = (setSending) => {

  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedImagesBlob, setSelectedImagesBlob] = React.useState(null);

  const [frm_name, setName] = React.useState("");
  const [frm_email, setEmail] = React.useState("");
  const [frm_mobileNumber, setMobileNumber] = React.useState("");
  const [frm_message, setMessage] = React.useState("");
  const [frm_title, setTitle] = React.useState("");
  const [frm_place, setPlace] = React.useState("");
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const today = new Date();
  const date = today.setDate(today.getDate() + 3); 

  React.useEffect(() => {
    setSelectedImagesBlob([]);
  }, []);

 const convertImage = (file) => {

 let reader = new FileReader();
 reader.onload = function(e) {

  let blob = e.target.result;


  selectedImagesBlob.push(blob);

   setSelectedImagesBlob(selectedImagesBlob);

   forceUpdate();

  document.getElementById("myImage").value = null;

 };
 reader.readAsDataURL(file);

}

const removeImage = (index) => {

  selectedImagesBlob.splice(index, 1);
  setSelectedImagesBlob(selectedImagesBlob);

}

  const postForm = () => {

    postObject = {
      type: "web_photos",
      name: frm_name,
      email: frm_email,
      phone: frm_mobileNumber,
      text: frm_message,
      title: frm_title,
      place: frm_place,
      imageblobs: selectedImagesBlob,
      app_id: getAppId()
    }

    console.log(postObject);

    if (frm_message == "" || frm_name == "" || frm_email == "" || frm_mobileNumber == "" ) {
      return 
    }

    setSending(1);

    return axios({
      method: "post",
      url: "https://api.lokal.app/api/postform",
      data: postObject
    })
      .then((res) => {

        setSending(2);

      })


  }
  

  return (
    <div className="photo-form">
    <form className="">
      

    <div className="">
        <div className="fs-5 fw-bold">1. Dina bilder</div>
        
        {selectedImagesBlob == null || selectedImagesBlob.length == 0 ? ( null ) : (

          selectedImagesBlob.map((image, index) => (
    
          (image == null) ? ( null ) : (
          <div ket={'img' + index}>
            <img alt="not fount" width={"250px"} src={image} /><br />
            <button className="btn form-control btn-secondary mt-3" onClick={() => { removeImage(index) }}>Radera bilden</button>
          </div>        
         ) )))}  

      </div>
      <div className="mb-3">
      <input
      type="file"
      name="myImage"
      id="myImage"
      className="form-control mt-3"
      accept="image/jpeg, image/jpg"
      onChange={(event) => {
        console.log(event.target.files[0]);
        setSelectedImage(event.target.files[0]);
        convertImage(event.target.files[0]);
      }}
    />
      </div>

      <div className="fs-5 fw-bold">2. Information om bilderna</div>

      <div className="mt-3 fs-6 fw-bold">Rubrik</div>
      <input type="text" className="form-control form-input" placeholder="Rubrik" required onChange={(e) => setTitle(e.target.value)} />

      <div className="mt-3 fs-6 fw-bold">Bildtexter</div>

      <div className="">
        <textarea
          rows="3"
          className="form-control form-input"
          placeholder="text"
          required
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>


      
      <label className="form-label fs-5 fw-bold mt-5">
        Inskickad av (Publiceras ej)
      </label>
      <div className="mb-1">
        <input
          type="text"
          className="form-control form-input"
          id="fullname"
          placeholder="För- och efternamn"
          required
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="mb-1">
        <input
          type="email"
          className="form-control form-input"
          id="email"
          placeholder="E-postadress"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mb-1">
        <input
          type="tel"
          className="form-control form-input"
          id="tel"
          placeholder="Telefonnummer"
          required
          onChange={(e) => setMobileNumber(e.target.value)}
        />
      </div>
      <button
        className="btn form-control btn-secondary mt-3"
        type="button"
        onClick={postForm}
      >
        Skicka
      </button>
    </form>
  </div>
  );

  
};

export default PhotoForm;
