import React from "react";
import { useNavigate } from "react-router-dom";
import "./lunch.css";
import Image from "../../../UI/Image";
import LunchLogic from "../../../pages/lunch/logic/LunchLogic";

const Lunch = () => {

  const { lunchData } = LunchLogic();

  const navigate = useNavigate();

  const navigateOnClick = () => {
    navigate(`/lunch`);
  }


  return (
    <>

      { lunchData.length > 0 && lunchData[0]["data"].length > 0 ? (
      <div className="rightSideBar-box p-3" onClick={navigateOnClick} role="buttton">
        <div className="fw-bold text-uppercase fs-5">dagens lunch</div>
        <div className="row gx-2">

          <div className="col-8">
          <div
          className="fs-6 lh-sm lines-4"
          dangerouslySetInnerHTML={{ __html: lunchData[0]["data"][0].body }}
        ></div>
          </div>
          <div className="col-4 img-max">
            <Image medias={lunchData[0]["data"][0].logo} type="thmb" />
          </div>  
        </div>
          <div className="fw-bold fs-6">Visa dagens lunch från ytterligare {lunchData[0]["data"].length} restauranger</div>
      </div>) : ( null )
      }
    </>
  );

};

export default Lunch;
