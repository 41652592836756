import axios from "axios";
import { getAppId } from "./getAppId";

export const getChurches = async (setChurchesData, setLoading) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/sectionforweb/3?app_id=" + getAppId(),
  })
    .then((res) => {
      setChurchesData(res.data);
      setLoading(false);
      console.log(res.data);
      console.log(res.data.sections);
    })
    .catch((e) => {
      console.log(e);
    });
};
