import React from "react";
import { getTodaysFlowers } from "../../../../../api/TodaysFlowers";
import {AppContext} from "../../../../../context/Data";

const TodayFlowerLogic = () => {
  const [todaysFlowersData, setTodaysFlowersData] = React.useState([]);
  const { loading, setLoading } = React.useContext(AppContext);

  React.useEffect(() => {
    getTodaysFlowers(setTodaysFlowersData, setLoading);
  }, []);
  return { todaysFlowersData, loading };
};

export default TodayFlowerLogic;
