import React from "react";
import { useParams } from "react-router-dom";
import { getTravelDetails } from "../../../../../api/Travel";
import {AppContext} from "../../../../../context/Data";

const ResaDetailsLogic = () => {
  const [travelDetailsData, setTravelDetailsData] = React.useState({});
  const [open, setOpen] = React.useState({});
  const { loading, setLoading } = React.useContext(AppContext);

  const params = useParams();

  const handleClick = (id) => {
    setOpen((open) => ({
      ...open,
      [id]: !open[id],
    }));
  };

  React.useEffect(() => {
    getTravelDetails(params.id, setTravelDetailsData, setLoading);
  }, []);
  return { travelDetailsData, open, handleClick, loading };
};

export default ResaDetailsLogic;
