import React from "react";
import { getReaderNews } from "../../../../../api/ReaderNews";
import { AppContext } from "../../../../../context/Data";

const ReaderNewsLogic = () => {
  const [readerNewsData, setReaderNewsData] = React.useState([]);
  const { loading, setLoading } = React.useContext(AppContext);

  React.useEffect(() => {
    getReaderNews(setReaderNewsData, setLoading);
  }, []);
  return { readerNewsData, loading };
};

export default ReaderNewsLogic;
