import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "moment/locale/sv";

const HappeningEvents = (props) => {

  const navigate = useNavigate();

  const navigateOnClick = () => {
    navigate(`/events/${props.id}`);
  }

  let dateToDisplay = moment
  .utc(props.datetime)
  .local("sv")
  .format("dddd D MMMM") + " kl. " + moment
  .utc(props.datetime)
  .local("sv")
  .format("HH:mm");

  return (
    <div className="row gx-2 mt-2" role='button' onClick={() => navigateOnClick()}>
      <div className="col-3 img-max">
      <img src={props.image} alt="" className="rounded-3" />
      </div>
      <div className="col-9">
        <div className="fs-5 fw-bold lh-1">{props.title}</div>
          <div className="fs-6 date-format">{dateToDisplay}</div>
          <div className="fs-6">{props.place}</div>
        </div>
      </div>
  );
};

export default HappeningEvents;
