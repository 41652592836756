import React from 'react'
import ReactGA from "react-ga4"
import { useLocation } from "react-router-dom";
import { getAppId } from '../../../api/getAppId';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import Global from '../../Global';
import axios from 'axios';

const ScrollToTopAndTracking = () => {
    const {pathname} = useLocation();

    const [cookies, setCookie] = useCookies(["web_" + getAppId()]);

    let appId = getAppId();
    let trackingId = "G-4L8B4XGRPB";
    switch(appId)
    {
      case 1:
        trackingId = "G-4L8B4XGRPB";
        break;
      case 2:
        trackingId = "G-HBZKGLGL57";
        break;
      case 30:
        trackingId = "G-FLM79C27C7";
        break;
      case 36:
        trackingId = "G-82P65PPTHY";
        break;
    }

    async function processLocalStats() {

      if (!cookies.did)
      {
        if (Global.did == "unset")
        {
          Global.did = await uuidv4()
        }
        await setCookie('did', Global.did, { path: '/', expires: new Date(2030, 1, 1)  });
        postDid(Global.did);
      }
      else
      {
        postDid(cookies.did);
      }

    }

    function postDid(did) {

      if (!Global.postedDid)
      {
        Global.postedDid = true;

        let userAgent = navigator.userAgent;
        let browserName;
        
        if(userAgent.match(/chrome|chromium|crios/i)){
            browserName = "Chrome";
          }else if(userAgent.match(/firefox|fxios/i)){
            browserName = "Firefox";
          }  else if(userAgent.match(/safari/i)){
            browserName = "Safari";
          }else if(userAgent.match(/opr\//i)){
            browserName = "Opera";
          } else if(userAgent.match(/edg/i)){
            browserName = "Edge";
          }else{
            browserName="No browser detection";
          }

        const postObject = {
          did: did,
          os: "Portal",
          app_version: "1.0",
          manufacturer: browserName,
          model: navigator.platform
        };
    
        axios({
          method: "post",
          url: "https://api.lokal.app/api/device?app_id=" + getAppId(),
          data: postObject
        })
        .then((res) => {
          Global.id = res.data.id;
          console.log("ID: " + Global.id);
        })
        .catch((e) => {
          console.log(e);
        });

      }

    }


    React.useEffect(() => {
        ReactGA.initialize([
          {
            trackingId: trackingId
          }
        ]);

        processLocalStats();

      }, [])

    React.useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        const currentPath = location.pathname + location.search;
        ReactGA.send({ hitType: "pageview", page: currentPath });

    }, [pathname])


  return null;
}

export default ScrollToTopAndTracking