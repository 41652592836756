import React, { useEffect } from "react";
import eventHeaderImg from "../../../../public/images/event-header-img.png";
import EventForm from "./EventForm";
import { useNavigate } from "react-router-dom";
import "./EventFormPage.css";
import PageHederImage from "../../UI/HeaderImage";

const EventFormPage = () => {

  const [sending, setSending] = React.useState(false);

  useEffect(() => {
    setSending(0);
 }, []);


  const navigate = useNavigate();
  return (
    <div className="event-form-page">

     <PageHederImage image={eventHeaderImg} text="Evenemang" />

      <div className="fw-bold fs-3 text-uppercase mt-3">skicka in evenemang</div>
      <div className="fs-5 mb-5">
        Skicka in ditt evenemang! Här samlar vi så många evenemang vi bara kan,
        stort som smått. Välkommen att skicka in evenemang!
      </div>
      { sending == 1 ? (
      <div class="alert alert-primary fs-5 mt-5" role="alert">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
          Skickar...
      </div>) : null}
      { sending == 2 ? (
      <div class="alert alert-primary fs-5 mt-5" role="alert">Tack för ditt bidrag, vi kommer att behandla det så fort vi kan.</div>) : null}
      { sending == 0 ? (<EventForm setSending={setSending} />) : null}
      <hr className="border border-1 border-dark" />
      <div className="fw-bold fs-6 text-uppercase text-end" onClick={() => navigate("/events")} role="button">se alla evenemang {">"}</div>
    </div>
  );
};

export default EventFormPage;
