import axios from "axios";

export const getTodayData = async (setTodayData, setLoading) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/today",
  })
    .then((res) => {
      setTodayData(res.data);
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
    });
};
