import React from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import Loading from "../UI/Loading";

//Use this to manipulate google maps api in Map component
let center = { lat: 57.39798, lng: 14.66736 }; //coordinates for Sävsjö kommun

const Map = ({ data, lat, long, height, fontsize, width }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  });

  const containerStyle = {
    height: `${height}`,
    fontSize: `${fontsize}`,
    width: `${width}`,
  };

  if (lat)
  {
   // center.lat = lat;
  }
  if (long)
  {
   // center.lng = long;
  }


  return (
    <>
      {!isLoaded ? (
        <Loading />
      ) : (

        <GoogleMap
          zoom={9.3}
          center={center}
          mapContainerStyle={containerStyle}
        >
          {Array.isArray(data) ? (
            data?.map((marker, i) => (
              <MarkerF
                position={{ lat: marker.lat, lng: marker.long }}
                key={i}
              />
            ))
          ) : (
            <MarkerF
              position={{
                lat: Number(lat),
                lng: Number(long),
              }}
            />
          )}
        </GoogleMap>
      )}
    </>
  );
};

export default Map;
