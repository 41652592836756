import React from "react";
import { getFreeJobs } from "../../../../../api/FreeJobs";
import { AppContext } from "../../../../../context/Data";

const FreeJobsLogic = () => {
  const [freeJobsData, setFreeJobsData] = React.useState([]);
  const {loading, setLoading} = React.useContext(AppContext);
  React.useEffect(() => {
    getFreeJobs(setFreeJobsData, setLoading);
  }, []);
  return {freeJobsData, loading};
};

export default FreeJobsLogic;
