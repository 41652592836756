import React, { useEffect } from "react";
import eventHeaderImg from "../../../../public/images/ortskampen.png";
import { useNavigate } from "react-router-dom";
import PageHederImage from "../../UI/HeaderImage";
import { getName, getShortName } from "../../../../api/App";
import QuizForm from "./QuizForm";

const QuizFormPage = () => {

  const [sending, setSending] = React.useState(false);


  const [ name, setName] = React.useState();
  const [ shortName, setShortName] = React.useState();

  useEffect(() => {
    setSending(0);
    getName(setName);
    getShortName(setShortName);
 }, []);

  return (
    <div className="event-form-page">

     <PageHederImage image={eventHeaderImg} text={shortName + 'kampen'} />

      <div className="fw-bold fs-3 text-uppercase mt-3">skicka in din fråga till {shortName}kampen</div>
      <div className="fs-5 mb-5">
        Skicka in lokala frågor till oss. Din fråga kommer at komma med i {name}s tävling. Ladda ner appen och klicka på {shortName}kampen i menyn för att vara med och tävla.
      </div>
      { sending == 1 ? (
      <div class="alert alert-primary fs-5 mt-5" role="alert">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
          Skickar...
      </div>) : null}
      { sending == 2 ? (
      <div class="alert alert-primary fs-5 mt-5" role="alert">Tack för ditt bidrag, vi kommer att behandla det så fort vi kan.</div>) : null}
      { sending == 0 ? (<QuizForm setSending={setSending} />) : null}
    </div>
  );
};

export default QuizFormPage;
