import React from "react";
import { useNavigate } from "react-router-dom";
import "./travel.css";
import Image from "../../../UI/Image";
import travelImg from "../../../../../public/images/resa.jpg";
import { getName } from "../../../../../api/App";

const Travel = () => {


  const navigate = useNavigate();

  const navigateOnClick = () => {
    navigate(`/resa`);
  }

  const [ name, setName] = React.useState();

  React.useEffect(() => {
    getName(setName);
  }, []);

  return (
      <div className="rightSideBar-box p-3" onClick={navigateOnClick} role="button">
        <div className="text-uppercase fs-5 fw-bold">Resa</div>
        <div className="row gx-2">
          <div className="col-9">
            <div className="fs-6">När går nästa buss eller tåg? Det hittar du i {name}.</div>
          </div>
          <div className="col-3">
            <Image medias={travelImg} type="absolute" />
          </div>
        </div>
      </div>
  );

};

export default Travel;
