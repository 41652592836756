import { Adsense } from "@ctrl/react-adsense";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AdvertiserAssociationAd from "../../advertiser/AdvertiserAssociationAd";
import AdvertiserCompanyAd from "../../advertiser/AdvertiserCompanyAd";
import AdvertiserHeadline from "../../advertiser/AdvertiserHeadline";
import AdvertiserInternalAd from "../../advertiser/AdvertiserInternalAd";
import AdvertiserNewsHome from "../../advertiser/AdvertiserNewsHome";
import AdvertiserNewsSide from "../../advertiser/AdvertiserNewsSide";
import ExternalAd from "../../UI/externalAd/externalAd";
import Loading from "../../UI/Loading";
import PortalHelmet from "../../UI/PortalHelmet";
import "./Home.css";
import HomeLogic from "./logic/HomeLogic";

const Home = () => {
  const { firstNews, firstCompanyAd, firstAssociationAd, feed, loading } =
    HomeLogic();

  const navigate = useNavigate();
  const location = useLocation();

  const [adKey, setAdKey] =  React.useState([]);
  const [adKey2, setAdKey2] =  React.useState([]);

  React.useEffect(() => {
    setAdKey(Math.random());
    setAdKey2(Math.random());
  }, [location]);


  return (
    <div className="home d-flex flex-column gap-3">
      {loading ? (
        <Loading />
      ) : (
        <>

        <PortalHelmet title={'Hem'} />

          <AdvertiserHeadline
            medias={firstNews[0]?.medias}
            title={firstNews[0]?.title}
            body={firstNews[0]?.preword ? '<b class="text-uppercase">' + firstNews[0]?.preword + "</b>" + firstNews[0]?.shortbody : firstNews[0]?.shortbody }
            key={'home' + firstNews[0]?.id}
            id={firstNews[0]?.medias.map((media) => media.id)}
            onClick={() => navigate(`/news/${firstNews[0]?.id}`)}
            date={firstNews[0]?.timestart}
          />
          <AdvertiserCompanyAd
            medias={firstCompanyAd[0]?.medias}
            title={firstCompanyAd[0]?.title}
            body={firstCompanyAd[0]?.body}
            customer={firstCompanyAd[0]?.customer}
            id={firstCompanyAd[0]?.id}
            key={'home' + firstCompanyAd[0]?.id}
          />
          <div className="double-news d-flex justify-content-between">
            {firstNews?.slice(1).map((item) => (
              <AdvertiserNewsSide
                medias={item.medias}
                title={item.title}
                body={item.shortbody}
                id={item.medias?.map((media) => media.id)}
                key={'home' + item.id}
                onClick={() => navigate(`/news/${item.id}`)}
                date={item.timestart}
              />
            ))}
          </div>
          <AdvertiserAssociationAd
            medias={firstAssociationAd[0]?.medias}
            title={firstAssociationAd[0]?.title}
            body={firstAssociationAd[0]?.body}
            customer={firstAssociationAd[0]?.customer}
            id={firstAssociationAd[0]?.id}
            key={'home' + firstAssociationAd[0]?.id}
          />

       

          {feed?.map((item, index) => (
            <>

          { index == 0 && (<div className="mb-3">
                            <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
                              <Adsense
                                key={adKey}
                                client="ca-pub-8986694224901758"
                                slot="1633696971"
                                style={{ display: "block" }}
                                format="fluid"
                                layoutKey="-fg-1b+8p-kn+fh"
                              />
                            </div>)}
          { index == 4 && (<div className="mb-3">
                            <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
                              <Adsense
                                key={adKey}
                                client="ca-pub-8986694224901758"
                                slot="5750299368"
                                style={{ display: "block" }}
                                format="fluid"
                                layoutKey="-6t+ed+2i-1n-4w"
                              />
                            </div>)}
          { index == 8 && (<div className="mb-3">
                            <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
                              <Adsense
                                key={adKey}
                                client="ca-pub-8986694224901758"
                                slot="6747805746"
                                style={{ display: "block" }}
                                format="fluid"
                                layoutKey="-ep-2o+n4-lt-zj"
                              />
                            </div>)}

          { index == 12 && (<div className="mb-3">
                            <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
                              <Adsense
                                key={adKey}
                                client="ca-pub-8986694224901758"
                                slot="6208982837"
                              />
                            </div>)}
          { index == 16 && (<div className="mb-3">
              <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
              <Adsense
                  key={adKey2}
                  client="ca-pub-8986694224901758"
                  slot="4157534569"
                />
              </div>)}

              {item.type === "ad" && (
                <AdvertiserCompanyAd
                  key={'home' + item.id}
                  medias={item.medias}
                  title={item.title}
                  body={item.body}
                  customer={item.customer}
                  id={item.id}
                />
              )}
              {item.type === "association_ad" && (
                <AdvertiserAssociationAd
                  medias={item.medias}
                  title={item.title}
                  body={item.body}
                  customer={item.customer}
                  id={item.id}
                  type={item.type}
                  key={'home' + item.id}
                  onClick={() => navigate(`/news/${item.id}`)}
                />
              )}

              {item.type === "news_headline" && (
                <AdvertiserHeadline
                  medias={item.medias}
                  title={item.title}
                  body={item.preword ? '<b class="text-uppercase">' + item.preword + "</b> " + item.shortbody.replace(/(<([^>]+)>)/gi, "") : item.shortbody}
                  id={item.medias?.map((media) => media.id)}
                  key={'home' + item.id}
                  onClick={() => navigate(`/news/${item.id}`)}
                />
              )}
              {item.type === "news" && (
                <AdvertiserNewsHome
                  medias={item.medias}
                  title={item.title}
                  body={item.preword ? '<b class="text-uppercase">' + item.preword + "</b> " + item.shortbody.replace(/(<([^>]+)>)/gi, "") : item.shortbody}
                  id={item.medias?.map((media) => media.id)}
                  key={'home' + item.id}
                  onClick={() => navigate(`/news/${item.id}`)}
                  date={item.timestart}
                />
              )}
              {item.type === "internal_ad" && (
                <AdvertiserInternalAd
                customer_id={item.customer_id}
                  title={item.title}
                  body={item.body}
                  key={'home' + item.id}
                  medias={item.medias}
                  media={item.media}
                />
              )}
            </>
          ))}
        </>
      )}
    </div>
  );
};

export default Home;
