import React from 'react'

const HomeStayForm = () => {
  return (
    <div className="event-form ">
      <form className="d-flex flex-column gap-3">
        <div className="mb-3">
          <input
            type="text"
            className="form-control form-input"
            id="Rubrik"
            placeholder="Rubrik"
            required
          />
        </div>
        <div className="mb-3">
          <textarea
            rows="3"
            className="form-control form-input"
            placeholder="Beskrivning"
            required
          />
        </div>
        <div className="mb-3">
          <button
            type="button"
            onClick={() => document.getElementById("formFile").click()}
            className="btn upload-pic text-white fs-3 p-3 text-uppercase"
          >
            Ladda upp bild
          </button>
          <input
            className="form-control"
            type="file"
            id="formFile"
            style={{ opacity: "0" }}
            name="formFile"
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control form-input"
            id="fotograf"
            placeholder="fotograf"
            required
          />
        </div>

        <button
          className="btn w-25 fs-2 send-form-btn align-self-end text-white text-uppercase"
          type="submit"
        >
          Skicka
        </button>
      </form>
    </div>
  );
}

export default HomeStayForm