import React from "react";
import { Link } from "react-router-dom";
import { setHeader } from "../../../helpers/setHeader";

const Menu = () => {

  let name = setHeader();

  return (

<nav className="navbar navbar-expand-lg bg-white mb-2">
  <div className="container">
    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div className="offcanvas-header">
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <ul className="navbar-nav fs-5 fw-bold m-auto">
          <li className="nav-item">
            <button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                <Link to="/" className="nav-link fs-5 fw-bold text-uppercase">Hem</Link>
            </button>
          </li>
          <li className="nav-item">
            <button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                <Link to="/lunch" className="nav-link fs-5 fw-bold text-uppercase">Dagens lunch</Link>
            </button>
          </li>
          <li className="nav-item">
            <button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
              <Link to="/events" className="nav-link fs-5 fw-bold text-uppercase">Evenemang</Link>
            </button>
          </li>     
          <li className="nav-item">
          <button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
              <Link to="/associationlife" className="nav-link fs-5 fw-bold text-uppercase">Föreningsinfo</Link>
            </button>
          </li>                    
          <li className="nav-item dropdown has-megamenu">
          <a className="nav-link dropdown-toggle text-uppercase" href="#" data-bs-toggle="dropdown">Allt innehåll</a>
          <div className="dropdown-menu megamenu container m-auto" role="menu">

            <div className="row justify-content-start justify-content-lg-around">



              <div className="col-12 col-lg-auto">
                <ul className="fs-5 fw-bold list-unstyled">
                  <li className="fw-normal fst-italic">Nyheter</li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/news" className="nav-link p-0 fs-5 fw-bold text-uppercase">Nyheter</Link>
                    </button></li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/policenews" className="nav-link p-0 fs-5 fw-bold text-uppercase">Polisnyheter</Link>
                    </button> </li>
                    { /*
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/readernews" className="nav-link p-0 fs-5 fw-bold text-uppercase">Läsarnyheter</Link>
                    </button> </li>
                    */ }
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/todaysflowers" className="nav-link p-0 fs-5 fw-bold text-uppercase">Dagens ros</Link>
                    </button> </li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/familynews" className="nav-link p-0 fs-5 fw-bold text-uppercase">Familjenyheter</Link>
                    </button> </li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/today" className="nav-link p-0 fs-5 fw-bold text-uppercase">Idag</Link>
                    </button> </li>
                </ul>
              </div>


              <div className="col-12 col-lg-auto">
                <ul className="fs-5 fw-bold list-unstyled">
                  <li className="fw-normal fst-italic">Information</li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/deadads" className="nav-link p-0 fs-5 fw-bold text-uppercase">Dödsannonser</Link>
                    </button> </li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/traficinfo" className="nav-link p-0 fs-5 fw-bold text-uppercase">Trafikinfo</Link>
                    </button> </li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/freejobs" className="nav-link p-0 fs-5 fw-bold text-uppercase">Lediga jobb</Link>
                    </button> </li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/residenceforsale" className="nav-link p-0 fs-5 fw-bold text-uppercase">Bostad till salu</Link>
                    </button> </li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/soldresidence" className="nav-link p-0 fs-5 fw-bold text-uppercase">Sålda bostäder</Link>
                    </button> </li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/offers" className="nav-link p-0 fs-5 fw-bold text-uppercase">Erbjudanden</Link>
                    </button></li>
                 


                </ul>
              </div>


              <div className="col-12 col-lg-auto">
                <ul className="fs-5 fw-bold list-unstyled">
                  <li className="fw-normal fst-italic">Tjänster</li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/weather" className="nav-link p-0 fs-5 fw-bold text-uppercase">Väder</Link>
                    </button> </li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/resa" className="nav-link p-0 fs-5 fw-bold text-uppercase">Resa</Link>
                    </button> </li>
                    { /*
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/pooltemperature" className="nav-link p-0 fs-5 fw-bold text-uppercase">Badtemperatur</Link>
                    </button> </li>
                    */ }
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/elprices" className="nav-link p-0 fs-5 fw-bold text-uppercase">Elpriser</Link>
                    </button> </li>

                </ul>
              </div>

              <div className="col-12 col-lg-auto">
                <ul className="fs-5 fw-bolder list-unstyled">
                  <li className="fw-normal fst-italic">Bidra</li>
                  { /*
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/debate" className="nav-link p-0 fs-5 fw-bold text-uppercase">Debatt</Link>
                    </button> </li>
                    */ }
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/photos" className="nav-link p-0 fs-5 fw-bold text-uppercase">Bilder</Link>
                    </button> </li>
                    { /*
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/buyandsell" className="nav-link p-0 fs-5 fw-bold text-uppercase">Köp & sälj</Link>
                    </button> </li>
                  */ }

                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/eventtips" className="nav-link p-0 fs-5 fw-bold text-uppercase">Tipsa om evenemang</Link>
                    </button>
                  </li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/nyhetstips" className="nav-link p-0 fs-5 fw-bold text-uppercase">Tipsa om nyheter</Link>
                    </button>
                  </li>  
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/quizform" className="nav-link p-0 fs-5 fw-bold text-uppercase">Bidra med frågor</Link>
                    </button>
                  </li>  
                  
                </ul>
              </div>

              <div className="col-12 col-lg-auto">
                <ul className="fs-5 fw-bolder list-unstyled">
                  <li className="fw-normal fst-italic">Hitta</li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/corporate" className="nav-link p-0 fs-5 fw-bold text-uppercase">Företag</Link>
                    </button> </li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/association" className="nav-link p-0 fs-5 fw-bold text-uppercase">Föreningar</Link>
                    </button> </li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/church" className="nav-link p-0 fs-5 fw-bold text-uppercase">Kyrkor</Link>
                    </button> </li>
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/watchdo" className="nav-link p-0 fs-5 fw-bold text-uppercase">Se & göra</Link>
                    </button> </li>
                    { /*
                  <li><button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                      <Link to="/homestay" className="nav-link p-0 fs-5 fw-bold text-uppercase">Hemester</Link>
                    </button> </li>
                    */ }
                </ul>
              </div>


          </div>

          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle fs-5 fw-bold text-uppercase" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Om oss
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            
            <li className="dropdown-item">
              <button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                  <Link to="/text/omoss" className="nav-link p-0 fs-5 fw-bold text-uppercase">Om {name}</Link>
              </button>
            </li>  
            <li className="dropdown-item">
              <button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                  <Link to="/download" className="nav-link p-0 fs-5 fw-bold text-uppercase">Ladda ner {name}</Link>
              </button>
            </li> 
            <li className="dropdown-item">
              <button className="p-0 border-0 btn btn-link" data-bs-dismiss="offcanvas">
                  <Link to="/text/annonsera" className="nav-link p-0 fs-5 fw-bold text-uppercase">Annonsera</Link>
              </button>
            </li>  

          </ul>
        </li>
 
        </ul>
      </div>
    </div>
  </div>
</nav>


  );
};

export default Menu;
