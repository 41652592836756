import React from "react";
import { Link } from "react-router-dom";

const RegisterForm = () => {
  return (
    <div className="">
      <form className="register-form d-flex flex-column">
        <div className="mb-3">
          <input
            type="email"
            className="form-control form-input"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="E-postadress"
          />
        </div>
        <div className="mb-3">
          <input
            type="password"
            className="form-control form-input"
            id="exampleInputPassword1"
            placeholder="Lösenord"
          />
        </div>
        <div className="mb-3 d-flex gap-3">
          <input
            type="checkbox"
            className="form-check-input fs-3"
            id="exampleCheck1"
          />
          <label className="form-check-label fs-3 w-75" for="exampleCheck1">
            Jag godkänner XXXAppens <Link>Allmänna villkor</Link>. Läs om hur
            XXXAppen behandlar din personliga information i vår{" "}
            <Link>Integritetspolicy</Link>.
          </label>
        </div>
        <button
          type="submit"
          className="btn register-btn align-self-end w-25 fs-2 text-white text-uppercase"
        >
          logga in
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;
