import React from "react";
import { getElectricity, getElectricityProduction } from "../../../../../api/Electricity";

const ElectricityLogic = () => {
  const [ electricityData, setElectricityData ] = React.useState([]);
  const [ electricityProductionData, setElectricityProductionData ] = React.useState([]);
  const [ loading , setLoading ] = React.useState([]);
  const [ loadingProduction , setLoadingProduction ] = React.useState([]);

  React.useEffect(() => {
    getElectricity(setElectricityData, setLoading);
    getElectricityProduction(setElectricityProductionData, setLoadingProduction);

  }, []);

  return { electricityData, electricityProductionData, loading, loadingProduction };
};

export default ElectricityLogic;
