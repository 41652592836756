import axios from "axios";
import { getAppId } from "./getAppId";

export const getResidenceForSale = async (
  setResidenceForSaleData,
  setLoading
) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/booli/forsale?app_id=" + getAppId(),
  })
    .then((res) => {
      setResidenceForSaleData(res.data);
      setLoading(false);
      console.log(res.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getSoldResidence = async (setSoldResidenceData, setLoading) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/booli/sold?app_id=" + getAppId(),
  })
    .then((res) => {
      setSoldResidenceData(res.data);
      setLoading(false);
      console.log(res.data);
    })
    .catch((e) => {
      console.log(e);
    });
};
