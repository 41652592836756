import axios from "axios";
import { getAppId } from "./getAppId";

export const getAdDetails = async (setAdDetails, setLoading, id) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: `https://api.lokal.app/api/adforweb/${id}?app_id=` + getAppId(),
  })
    .then((res) => {
      setAdDetails(res.data);
      setLoading(false);
      console.log(res.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getShareImg = async (setShareImg, type, type_id) => {
  return await axios({
    method: "get",
    url: 'https://api.lokal.app/api/shareimage/getImage?app_id=' + getAppId() + '&type=' + type + '&type_id=' + type_id,
  })
    .then((res) => {
      setShareImg(res.data);
    })
    .catch((e) => {
      console.log(e);
    });
};


