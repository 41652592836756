import React from "react";

const PageHederImage = ({ image, text }) => {
 
  return (
    <div className="page-header">
      <img src={image} alt="" className="header-img" />
      <div className="header-text fs-3 fw-bolder text-uppercase">{text}</div>
    </div>
  );

};

export default PageHederImage;
