import React from "react";
import { useNavigate } from "react-router-dom";
import readerNewsImg from "../../../../public/images/nyheter.png";
import AdvertiserNewsSide from "../../advertiser/AdvertiserNewsSide";
import PageHederImage from "../../UI/HeaderImage";
import Loading from "../../UI/Loading";
import NewsLogic from "./logic/NewsLogic";
import "./News.css";

const ReaderNews = () => {
  const { NewsData, loading } = NewsLogic();

  const navigate = useNavigate();
  return (
    <div className="">
      
      <PageHederImage image={readerNewsImg} text="Nyheter" />

      {loading || NewsData.length == 0 ? (
        <Loading />
      ) : (
        <div className="news mt-3">
          {NewsData?.map((item) => (
            <div className="mb-3" role="button">
            <AdvertiserNewsSide
              medias={item.medias}
              title={item.title}
              body={item.shortbody}
              id={item.id}
              key={item.id}
              onClick={() => navigate(`/news/${item.id}`)}
              date={item.timestart}
            />
            </div>
          ))}
        <div className="clear_both"></div>
        </div>
     
      )}
    </div>
  );
};

export default ReaderNews;
