import React from "react";

const CustomerName = ({ customer }) => {
  return (
    <div className="advertiser-name-logo d-flex align-items-center gap-2">
      {customer?.show == "all" && (
        <>
          <img
            src={`https://cdn.lokal.app/uploads/${customer?.logo}`}
            alt=""
            className=""
          />
          <div className="fs-6 fw-bold">{customer?.name}</div>

          <div className="fs-6 fw-bold">i {customer?.city}</div>
        </>
      )}
      {customer?.show == "city" && (
        <>
          <img
            src={`https://cdn.lokal.app/uploads/${customer?.logo}`}
            alt=""
            className=""
          />
          <div className="fs-6 fw-bold">i {customer?.city}</div>
        </>
      )}
      {customer?.show == "alt" && (
        <>
          <img
            src={`https://cdn.lokal.app/uploads/${customer?.logo}`}
            alt=""
            className="g"
          />
          <div className="fs-6 fw-bold">{customer?.alt_name}</div>
        </>
      )}
      {customer?.show == "name" && (
        <>
          <img
            src={`https://cdn.lokal.app/uploads/${customer?.logo}`}
            alt=""
            className=""
          />
          <div className="fs-6 fw-bold ">{customer?.name}</div>
        </>
      )}
      {customer?.show == "logo" && (
        <img
          src={`https://cdn.lokal.app/uploads/${customer?.logo}`}
          alt=""
          className=""
        />
      )}
    </div>
  );
};

export default CustomerName;