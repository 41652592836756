import axios from "axios";
import { getAppId } from "./getAppId";

export const getElectricity = async (setElectricityData, setLoading) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/electricity?app_id=" + getAppId(),
  })
    .then((res) => {

      let retvalData:any[] = [];
      let chartDataLabels:any[] = [];
      let chartDataSet:any[] = [];
      let chartDataSetTomorrow:any[] = [];
      let area = "";

      for (let i = 0; i < 24; i++) {
    
        area = res.data.data[i].area;

        if (res.data.data.length == 48)
        {
          chartDataLabels.push(res.data.data[i].time.split(':')[0].split(" ")[1]);
          chartDataSet.push(+(res.data.data[i].price / 100).toFixed(2));
          chartDataSetTomorrow.push(+(res.data.data[i + 24].price / 100).toFixed(2));


          retvalData.push({
            name: res.data.data[i].time.split(':')[0].split(" ")[1],
            price: (res.data.data[i].price / 100).toFixed(2),
            tomorrow: (res.data.data[i + 24].price / 100).toFixed(2)
          });
        }
        else
        {
          chartDataLabels.push(res.data.data[i].time.split(':')[0].split(" ")[1]);
          chartDataSet.push(+(res.data.data[i].price / 100).toFixed(2));

          retvalData.push({
            name: res.data.data[i].time.split(':')[0].split(" ")[1],
            price: (res.data.data[i].price / 100).toFixed(2)
          });
        }
     
      }
    

      if (chartDataSetTomorrow.length > 0)
      {
        let data = {
          labels: chartDataLabels,
          datasets: [
            {
              type: 'bar' as const,
              label: 'Elpriset idag',
              backgroundColor: 'rgb(75, 192, 192)',
              data: chartDataSet,
              borderColor: 'white',
              borderWidth: 2,
              order: 2
            },
            {
              type: 'line' as const,
              label: 'Elpriset imorgon',
              borderColor: 'rgb(255, 99, 132)',
              borderWidth: 2,
              fill: false,
              data: chartDataSetTomorrow,
              order: 1
            }
          ],
        };

        let retval = {
          data: retvalData,
          chart : data,
          area: area
        }
        setElectricityData(retval);
        setLoading(false);

      }
      else
      {
        let data2 = {
          labels: chartDataLabels,
          datasets: [
            {
              type: 'bar' as const,
              label: 'Elpriset idag',
              backgroundColor: 'rgb(75, 192, 192)',
              data: chartDataSet,
              borderColor: 'white',
              borderWidth: 2,
            }
          ],
        };


        let retval = {
          data: retvalData,
          chart : data2,
          area: area
        }
        setElectricityData(retval);
        setLoading(false);

      }

    

    })
    .catch((e) => {
      console.log(e);
    });
};

export const getElectricityProduction = async (setElectricityProductionData, setLoading) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/electricity_production?app_id=" + getAppId(),
  })
    .then((res) => {
      setElectricityProductionData(res.data.data);
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
    });
};
