import React from "react";
import Header from "../header/Header";
import "./Layout.css";
import LeftBar from "./left_bar/LeftBar";
import Main from "./main/Main";
import RightBar from "./right_bar/RightBar";

const Layout = (props) => {
  return (
    <div className="layout">
      <Header />
      <div className="container">
        <div className="row gx-4">
          <div className="d-none d-sm-none d-md-none d-lg-block col-lg-2">
            <LeftBar />
          </div>
          <div className="col-sm-12 col-md-8 col-lg-6">
            <Main>{props.children}</Main>
          </div>
          <div className="d-none d-sm-none d-md-block col-md-4">
            <RightBar />
          </div>
        </div>
      </div>

      <footer className="text-center text-lg-start bg-light text-muted">
        <div className="text-center p-4">
          © 2023 Copyright: <a className="text-reset fw-bold" href="#">Power App Marketing Sweden AB</a>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
