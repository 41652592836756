import React from "react";
import residenceImg from "../../../../public/images/residence.png";
import Map from "../../maps/Map";
import SoldResidenceLogic from "./logic/SoldResidenceLogic";
import SoldResidenceItem from "./SoldResidenceItem";
import Loading from "../../UI/Loading";
import PageHederImage from "../../UI/HeaderImage";

const SoldResidence = () => {
  const { soldResidenceData, longitude, latitude, loading } = SoldResidenceLogic();
  return (
    <div className="sold-residence d-flex flex-column gap-3">
      <div>
        <div className="residence-for-sale d-3">
          
          <PageHederImage image={residenceImg} text="Sålda bostäder" />

          <div className="karta fs-1 mt-3 mb-3">
            <Map data={soldResidenceData} long={longitude} lat={latitude} height={"500px"} fontsize={"100px"} />
          </div>
          {loading ? (
            <Loading />
          ) : (
            soldResidenceData?.map((item) => (
              <div key={item.id}>
                <SoldResidenceItem
                  type={item.type}
                  address={item.address}
                  place={item.place}
                  url={item.url}
                  price={item.price + " kr"}
                  published={item.published.slice(0, 16)}
                />
                <hr className="border border-1 border-dark" />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SoldResidence;
