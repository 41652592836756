import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { getAssociations } from "../../../../../api/Association";
import { getChurches } from "../../../../../api/Churches";
import { getCorporates } from "../../../../../api/Corporate";
import { getWatchAndDo } from "../../../../../api/Watch&Do";
import { AppContext } from "../../../../../context/Data";

const AdvertiserLogic = () => {
  const [advertiserData, setAdvertiserData] = React.useState({});
  const { loading, setLoading } = React.useContext(AppContext);
  const [section, setSection] = React.useState("");
  const [active, setActive] = React.useState("All");
  const location = useLocation();

  const getSectionOnClick = (id) => {
    let sectionName = advertiserData.sections.find((x) => x.id === id).name;
    setSection(sectionName);
    setActive(sectionName);
  };
  const showAllCorporatesOnClick = () => {
    setSection("");
    setActive("All");
  };

  React.useEffect(() => {
    location.pathname.includes("corporate") &&
      getCorporates(setAdvertiserData, setLoading);

    location.pathname.includes("association") &&
      getAssociations(setAdvertiserData, setLoading);

    location.pathname.includes("church") &&
      getChurches(setAdvertiserData, setLoading);

    location.pathname.includes("watchdo") &&
      getWatchAndDo(setAdvertiserData, setLoading);
  }, [location.pathname]);

  return {
    advertiserData,
    loading,
    section,
    active,
    getSectionOnClick,
    showAllCorporatesOnClick,
    location,
  };
};

export default AdvertiserLogic;
