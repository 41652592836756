import React from "react";
import { useNavigate } from "react-router-dom";
import "./weather.css";
import Image from "../../../UI/Image";
import WeatherLogic from "../../../pages/weather/logic/WeatherLogic";

const Weather = () => {


  const navigate = useNavigate();

  const navigateOnClick = () => {
    navigate(`/weather`);
  }

  const { places , loading, getPlaceOnClick, weatherData } = WeatherLogic();

  return (
    <>

      { weatherData.length > 0 ? (<div className="rightSideBar-box p-3" onClick={navigateOnClick} role="button">
          <div className="text-uppercase fs-5 fw-bold">Väder</div>
          <div className="row gx-2">
            <div className="col-7">
              <div className="fs-6">Idag är högsta temperaturen</div>
              <div className="fs-6">och lägsta temperaturen</div>
            </div>
            <div className="col-2">
              <div className="fs-6 fw-bold">{weatherData[0].max} ⁰C</div>
              <div className="fs-6 fw-bold">{weatherData[0].min} ⁰C</div>
            </div>
            <div className="col-3">
              <Image medias={'https://api.lokal.app/imgs/' + weatherData[0].icon} type="absolute" />
            </div>
          </div>
        </div>) : ( null )
      }
    </>
  );

};

export default Weather;
