import React from "react";
import { useNavigate } from "react-router-dom";
import skorpor from "../../../public/images/skorpor.png";

//Header(ad type), Title, image, description, advertiser

const CompanyAd = (props) => {
  const navigate = useNavigate();

  if (props.page == true) {
    return (
      <div className=""></div>
    )
  }

  return (
    <div
      className={`d-flex flex-column company-ad ${
        props.side === "right" && "m-0 p-3"
      }`}
      onClick={() => navigate("/ad")}
    >
      <p className="ad-type text-uppercase">{props.header}</p>
      <div
        className={`d-lg-flex ${
          props.side === "right" && "align-items-center"
        }`}
      >
        <div className="d-flex flex-column gap-3">
          <div className="fs-3 fw-bold">Saffransskorpor på burk!</div>
          <p className="company-ad-description fs-6">
            Så här ser det ut när OlofVictors i Glemminge bakar saffransskorpor.
            De bakar för hand med naturliga råvaror utan tillsatser i vedeldad
            ugn. Hur gott ser inte ut så säg? Finns hos oss på
          </p>
        </div>
        <img
          src={skorpor}
          alt=""
          className={`${props.side === "right" && "ads-right-img"}`}
        />
      </div>
    </div>
  );
};

export default CompanyAd;
