import axios from "axios";
import { getAppId } from "./getAppId";

export const getSpecialAd = async (setAd, setLoading, type) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: `https://api.lokal.app/api/ad/special?type=${type}Ad&app_id=` + getAppId(),
  })
    .then((res) => {
      setAd(res.data);
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
    });
};
