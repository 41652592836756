import React from "react";
import { FiExternalLink } from "react-icons/fi";

const FreeJobsItem = ({ title, content, url }) => {
  const convertToHTML = (htmlContent) => {
    return { __html: htmlContent };
  };

  return (
    <div className="free-jobs-item d-flex flex-column">
      <div
        className="free-jobs-item-title fw-bold fs-5"
        dangerouslySetInnerHTML={convertToHTML(title)}
      ></div>
      <div className="free-jobs-item-body d-flex">
        <div
          className="free-jobs-item-desc fs-6"
          dangerouslySetInnerHTML={convertToHTML(content)}
        ></div>
        <div className="free-jobs-item-icon ms-auto  text-center align-self-end">
          <a href={url} target="_blank" rel="noopener noreferrer">
            <FiExternalLink className="fs-2" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FreeJobsItem;
