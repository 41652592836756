import React from "react";
import residenceImg from "../../../../public/images/erbjudanden.png";
import { OffersLogic } from "./logic/OffersLogic";
import Loading from "../../UI/Loading";
import {useParams, useNavigate} from "react-router-dom";
import "./Offers.css";
import PageHederImage from "../../UI/HeaderImage";
import AdvertiserNameLogo from "../../advertiser/AdvertiserNameLogo";
import Image from "../../UI/Image";

const Offers = () => {
  const { offersData, loading, currentIndex, setCurrentIndex } = OffersLogic();
  const params = useParams();
  const navigate = useNavigate();
  const navigateOnClick = (id) => {
    navigate(`/ad/${id}`);
  }


  return (
    <div className="offers">

      <PageHederImage image={residenceImg} text="Erbjudanden" />

      {loading ? (
        <Loading />
      ) : (

        <>
      {offersData.map((item) => (
          
          <div
      className="grayBackground p-3 mb-3"
      onClick={() => navigateOnClick(item.ad_id)}
    >

      <div className="row">
        <div className="col-9">
          <div className={`fs-3 fw-bold lh-sm`}>
            {item.title}
          </div>
          <div
            className={`fs-5 lines-4 lh-sm`}
            dangerouslySetInnerHTML={{ __html: item.body }}
          ></div>
        </div>
        <div className="col-3 img-max">
          <Image medias={item.medias} type="thmb" />
        </div>
      </div>
      <div className='mt-3'>
        <AdvertiserNameLogo data={item.customer} />
      </div>
    </div>
      ))}
      </>
      )}
    
    </div>
  );
};

export default Offers;
