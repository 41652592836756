import React from "react";
import { getLunch } from "../../../../../api/Lunch";
import { AppContext } from "../../../../../context/Data";

const LunchLogic = () => {
  const [ lunchData, setLunchData ] = React.useState([]);
  const [ loading, setLoading ] = React.useState([]);

  React.useEffect(() => {
    getLunch(setLunchData, setLoading);
  }, []);

  return { lunchData, loading };
};

export default LunchLogic;
