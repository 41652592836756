import React from "react";
import { useNavigate } from "react-router-dom";
import "./today.css";
import Image from "../../../UI/Image";
import todayImg from "../../../../../public/images/today.jpg";
import { getTodayData } from "../../../../../api/Today";

const Today = () => {

  const navigate = useNavigate();

  const navigateOnClick = () => {
    navigate(`/today`);
  }

  const [ todayData, setTodayData] = React.useState();
  const [ loading, setLoading ] = React.useState();

  React.useEffect(() => {
    getTodayData(setTodayData, setLoading);
  }, []);

  return (
    <>
      { todayData ? (<div className="rightSideBar-box p-3" onClick={navigateOnClick} role="button">
          <div className="text-uppercase fs-5 fw-bold">Idag</div>
          <div className="row gx-2">
            <div className="col-9">
              <div className="fs-6">{todayData.nameday} har namnsdag idag.</div>
              <div className="fs-6 fw-bold">Läs vidare om dagens temadagar och historiska händelser</div>
              
            </div>
            <div className="col-3">
              <Image medias={todayImg} type="absolute" />
            </div>
          </div>
        </div>) : ( null )
      }
    </>
  );

};

export default Today;
