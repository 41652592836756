import React from "react";
import Happening from "../../sidebar/right/happening/Happening";

import WorkAd from "../../sidebar/right/workAd/workAd";
import FormRightBar from "../../UI/FormRightBar";
import "./RightBar.css";
import MostRead from "../../sidebar/right/most_read/MostRead";
import Weather from "../../sidebar/right/weather/weather";
import Travel from "../../sidebar/right/travel/travel";
import Lunch from "../../sidebar/right/lunch/lunch";
import Today from "../../sidebar/right/today/today";

import { Adsense } from '@ctrl/react-adsense';
import { useLocation } from "react-router-dom";
import ExternalAd from "../../UI/externalAd/externalAd";

const RightBar = () => {
  const currentPath = window.location.pathname;

  const location = useLocation();

  const [adKey, setAdKey] =  React.useState([]);

  React.useEffect(() => {
    setAdKey(Math.random());
  }, [location]);



  return (
    <div className="right-bar d-flex flex-column gap-3">
      {currentPath == "/events" ? (
        <FormRightBar
          title="Skicka in evenemang"
          description="Skicka in ditt evenemang! Här samlar vi så många evenemang vi bara
          kan, stort som smått. Välkommen att skicka in evenemang!"
          to="/eventform"
        />
      ) : null}
      {currentPath == "/todaysflowers" ? (
        <FormRightBar
          title="skicka in dagens ros"
          description="Ge Dagens ros till någon som förtjänar extra tack eller uppskattning."
          to="/todayflowersform"
        />
      ) : null}
      {currentPath == "/familynews" ? (
        <FormRightBar
          title="skicka in familjenyhet"
          description="Uppmärksamma födelsedagar, jubilarer och bröllopsdagar med en
          hälsning. Annonsera förlovning, vigsel, nyfödd och adoption i appen."
          to="/familynewsform"
        />
      ) : null}
      {currentPath == "/photos" ? (
        <FormRightBar
          title="skicka in bild"
          description="Skicka in dina finaste bilder från Sävsjö kommun till oss. Nytagna
        bilder eller äldre bilder med historia är välkomna."
          to="/photosform"
        />
      ) : null}
      {currentPath == "/readernews" ? (
        <FormRightBar
          title="skicka in läsarnyhet"
          description="Skriv en egen nyhet och skicka in till appen för publicering"
          to="/readernewsform"
        />
      ) : null}
      <MostRead />

      <Adsense
        key={adKey}
        client="ca-pub-8986694224901758"
        slot="5343773471"
        format="auto"
        fullWidthResponsive="true"
        style={{ display: "block" }}
      />

      <Happening />
      <WorkAd />
      <Lunch />

      <Adsense
        key={adKey}
        client="ca-pub-8986694224901758"
        slot="5163411356"
      />

      <Today />
      <Weather />
      <Travel />
    </div>
  );
};

export default RightBar;
