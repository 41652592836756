import React from "react";
import { useNavigate } from "react-router-dom";

const FormRightBar = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className={`form-right-bar p-3 d-flex flex-column gap-3 ${
        props.hide === true && "d-none"
      }`}
    >
      <div>
        <h1 className="text-uppercase fw-bold fs-5">{props.title}</h1>
        <div className="fs-6">{props.description}</div>
      </div>
      <button
        className="fs-6 text-uppercase text-white send-in p-2 rounded"
        onClick={() => navigate(props.to)}
      >
        skicka in
      </button>
    </div>
  );
};

export default FormRightBar;
