import React from "react";
import { useNavigate } from "react-router-dom";
import eventHeaderImg from "../../../../public/images/event-header-img.png";
import "./EventDetails.css";
import { useParams } from "react-router-dom";
import { getEventDetails } from "../../../../api/Events";

import moment from "moment";
import "moment/locale/sv";
import PageHederImage from "../../UI/HeaderImage";
import Image from "../../UI/Image";

//Title
//Info
//img
//description

const EventDetails = () => {

    const [ eventDetailsData , setEventDetailsData] = React.useState({});
    const params = useParams();

    React.useEffect(() => {
      getEventDetails(params.id, setEventDetailsData);
    }, [params.id]);


  const navigate = useNavigate();

  return (
    <div className="event-details">

      <PageHederImage image={eventHeaderImg} text="Evenemang" />
  
      <div className="fw-bold fs-3 mt-3 mb-3">{eventDetailsData.title}</div>
      <div className="fw-bold fs-6 text-uppercase">{moment.utc(eventDetailsData.date).local("sv").format("dddd D MMMM")} {moment.utc(eventDetailsData.starttime).local("sv").format("HH:mm")}-{moment.utc(eventDetailsData.endtime).local("sv").format("HH:mm")}</div>
      <div className="fw-bold fs-6 text-uppercase">Plats: {eventDetailsData.place}</div>

      <div className="img-max mt-3">
        <Image medias={eventDetailsData.media} type="src" />
      </div>

      <div className="fs-6 mb-3 mt-3">{eventDetailsData.txt}</div>

      <div className="fw-bold fs-6 text-uppercase text-end" onClick={() => navigate("/events")} role="button">se fler evenemang {">"}</div>
      <div className="fw-bold fs-6 text-uppercase text-end" onClick={() => navigate("/eventform")} role="button">skicka in evenemang {">"}</div>

    </div>
  );
};

export default EventDetails;
