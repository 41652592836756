import React from "react";

const AdvertiserContact = ({ data, contactPersons }) => {
  if (contactPersons === "contactPersons") {
    return (
      <div className="advertiser-details-contact d-flex flex-wrap gap-5">
        {data.contacts?.map((contact, i) => (
          <div className="" key={i}>
            <h5 className="fw-bold">{contact.name}</h5>
            {contact.title?.length > 0 && <h5>{contact.title}</h5>}
            <h5>{contact.phone}</h5>
            {contact.email?.length > 0 && <h5>{contact.email}</h5>}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="advertiser-details-contact">
      <h5 className="fw-bold">Telefon</h5>
      {data.profile_phone != null && <h5>{data.profile_phone}</h5>}
      <h5 className="fw-bold">Mail</h5>
      {data.profile_email != null && <h5>{data.profile_email}</h5>}
    </div>
  );
};

export default AdvertiserContact;
