import React from "react";
import { getPoliceNews } from "../../../../../api/PoliceNews";
import { AppContext } from "../../../../../context/Data";
const PoliceNewsLogic = () => {
  const [policeNewsData, setPoliceNewsData] = React.useState([]);
  const { loading, setLoading } = React.useContext(AppContext);

  React.useEffect(() => {
    getPoliceNews(setPoliceNewsData, setLoading);
  }, []);

  return { policeNewsData, setPoliceNewsData, loading, setLoading };
};

export default PoliceNewsLogic;
