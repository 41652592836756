import React from "react";
import { getTravel } from "../../../../api/Travel";
import resaImg from "../../../../public/images/resa.png";
import "./Resa.css";
import ResaItem from "./ResaItem";
import { AppContext } from "../../../../context/Data";
import Loading from "../../UI/Loading";
import PageHederImage from "../../UI/HeaderImage";

const Resa = () => {
  const [travelData, setTravelData] = React.useState([]);
  const { loading, setLoading } = React.useContext(AppContext);

  React.useEffect(() => {
    getTravel(setTravelData, setLoading);
  }, []);
  console.log(travelData);
  return (
    <div className="resa">
      <PageHederImage image={resaImg} text="Resa" />

      <div className="resa-body">
        <div className="fw-bold fs-5 text-uppercase mb-4">visa kommande avgångar från</div>

        {loading ? (
          <Loading />
        ) : (
          travelData?.map((item) => (
            <div key={item.id}>
              <ResaItem name={item.name} id={item.id} />
              <hr className="border border-1 border-dark" />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Resa;
