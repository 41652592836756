import React from "react";

const AdvertiserNameLogo = ({ data }) => {
  return (
    <div className="advertiser-name-logo d-flex align-items-center gap-2">
      {data?.show == "all" && (
        <>
          <img
            src={`https://cdn.lokal.app/uploads/${data?.logo}`}
            alt=""
            className=""
          />
          <div className="fs-5 fw-bold">{data?.name}</div>
          <div className="fs-5 fst-italic">i {data?.city}</div>
        </>
      )}
      {data?.show == "city" && (
        <>
          <img
            src={`https://cdn.lokal.app/uploads/${data?.logo}`}
            alt=""
            className=""
          />
          <div className="fs-5 fst-italic">i {data?.city}</div>
        </>
      )}
      {data?.show == "alt" && (
        <>
          <img
            src={`https://cdn.lokal.app/uploads/${data?.logo}`}
            alt=""
            className="g"
          />
          <div className="fs-5 fw-bold">{data?.alt_name}</div>
        </>
      )}
      {data?.show == "name" && (
        <>
          <img
            src={`https://cdn.lokal.app/uploads/${data?.logo}`}
            alt=""
            className=""
          />
          <div className="fs-5 fw-bold">{data?.name}</div>
        </>
      )}
      {data?.show == "logo" && (
        <img
          src={`https://cdn.lokal.app/uploads/${data?.logo}`}
          alt=""
          className=""
        />
      )}
    </div>
  );
};

export default AdvertiserNameLogo;
