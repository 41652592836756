import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const AdvertiserPages = ({ data }) => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div
      className={`${data.pages?.length <= 0 && "justify-content-center"}`}
    >
      {data.profile_webb != null && (
        <div className="text-center">
          <a
            href={
              !data.profile_webb.includes("http")
                ? `https://${data.profile_webb}`
                : data.profile_webb
            }
            target="_blank"
            rel="noopener noreferrer"
            className="advertiser-details-pages-btn fs-4 text-white text-uppercase"
          >
            Besök vår hemsida
          </a>
        </div>
      )}

      {data.pages.length > 0 &&
        data.pages?.map((page) => (
          <div className=" text-center" key={page.id}>
            {page.typ === "textsida" ? (
              <button
                className="advertiser-details-pages-btn fs-4 text-white text-uppercase"
                onClick={() =>
                  navigate(`/advertiser/${params.id}/advertiserunderpage`)
                }
              >
                {page.title}
              </button>
            ) : (
              <a
                href={page.url}
                target="_blank"
                rel="noopener noreferrer"
                className="advertiser-details-pages-btn fs-4 text-white text-uppercase"
              >
                {page.title}
              </a>
            )}
          </div>
        ))}
    </div>
  );
};

export default AdvertiserPages;
