import React from "react";
import { AppContext } from "../../../../context/Data";
import freeJobsImg from "../../../../public/images/freejobs.png";
import Ads from "../../ads/Ads";
import "./FreeJobs.css";
import FreeJobsItem from "./FreeJobsItem";
import FreeJobsLogic from "./logic/FreeJobsLogic";
import Loading from "../../UI/Loading";
import PageHederImage from "../../UI/HeaderImage";
import PortalHelmet from "../../UI/PortalHelmet";
import { Adsense } from "@ctrl/react-adsense";
import ExternalAd from "../../UI/externalAd/externalAd";
import { useLocation } from "react-router-dom";

const FreeJobs = () => {
  const { freeJobsData, loading } = FreeJobsLogic();

  const location = useLocation();

  const [adKey, setAdKey] =  React.useState([]);
  const [adKey2, setAdKey2] =  React.useState([]);

  React.useEffect(() => {
    setAdKey(Math.random());
    setAdKey2(Math.random());
  }, [location]);


  return (
    <div className="free-jobs">

      <PageHederImage image={freeJobsImg} text="Lediga jobb" />
      <PortalHelmet title="Lediga jobb" />

      <div className="mt-3">

      {loading ? (
        <Loading />
      ) : (
        freeJobsData?.map((item, index) => (
          <>

          { index == 2 && (<ExternalAd format="middle" />)}
          { index == 8 && (<ExternalAd format="middle" />)}
          { index == 16 && (<ExternalAd format="middle" />)}
          { index == 5 && (<div className="mb-3">
                            <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
                              <Adsense
                                key={adKey}
                                client="ca-pub-8986694224901758"
                                slot="6208982837"
                              />
                            </div>)}
          { index == 12 && (<div className="mb-3">
              <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
              <Adsense
                  key={adKey2}
                  client="ca-pub-8986694224901758"
                  slot="4157534569"
                />
              </div>)}

            <FreeJobsItem
              content={item.content}
              url={item.url}
              title={item.content}
            />
            <hr className="border border-1 border-dark" />
          </>
        ))
      )}

      </div>

    </div>
  );
};

export default FreeJobs;
