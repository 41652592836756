import React from "react";
import { getCordinates } from "../../../../../api/App";
import { getResidenceForSale } from "../../../../../api/Residence";
import { AppContext } from "../../../../../context/Data";

const ResidenceForSaleLogic = () => {
  const [residenceForSaleData, setResidenceForSaleData] = React.useState([]);
  const [ loading, setLoading ] = React.useState([]);
  const [ longitude, setLongitude] = React.useState([]);
  const [ latitude, setLatitude] = React.useState([]);


  React.useEffect(() => {
    getResidenceForSale(setResidenceForSaleData, setLoading);
    getCordinates(setLongitude, setLatitude)
  }, []);
  return { residenceForSaleData, longitude, latitude, loading };
};

export default ResidenceForSaleLogic;
