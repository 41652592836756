import React from "react";
import { useNavigate } from "react-router-dom";
import ImageThmbSlider from "../UI/ImageThmbSlider";
import AdvertiserNameLogo from "./AdvertiserNameLogo";

const AdvertiserAssociationAd = ({
  medias,
  title,
  body,
  customer,
  currentIndex,
  setCurrentIndex,
  id,
}) => {
  const navigate = useNavigate();
  const navigateOnClick = () => {
    navigate(`/ad/${id}`);
  };
  return (
    <div
      className="advertiser-association-ad p-3 d-flex flex-column gap-3"
      onClick={() => navigateOnClick()}
    >
      <div className="fs-6 fw-bold text-uppercase text-muted">Föreningsinformation</div>

      <div className="row">
        <div className="col-8">
          <div className={`fs-4 fw-bold lh-sm mb-2`}>
            {title}
          </div>
          <div
            className={`fs-5 lines-4 lh-sm`}
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
        </div>
        <div className="col-4 img-max">
          {medias?.length > 1 ? (
            <ImageThmbSlider
              slides={medias}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              id={medias.map((media) => media.id)}
              className="association-ad-slider-imgs"
            />
          ) : (
            <img
              src={`https://cdn.lokal.app/uploads/${medias?.map(
                (media) => media.src_thmb
              )}`}
              alt=""
              className={`association-ad-img rounded-3 ms-auto`}
            />
          )}
        </div>
      </div>
      <AdvertiserNameLogo data={customer} />
    </div>
  );
};

export default AdvertiserAssociationAd;
