import React from "react";
import { useParams } from "react-router-dom";
import { getAdDetails, getShareImg } from "../../../../api/AdDetails";
import { AppContext } from "../../../../context/Data";
import Byline from "../../UI/Byline";
import PageHederImage from "../../UI/HeaderImage";
import ImageSlider from "../../UI/ImageSlider";
import Loading from "../../UI/Loading";
import PortalHelmet from "../../UI/PortalHelmet";
import readerNewsImg from "../../../../public/images/nyheter.jpg";

import "./NewsDetails.css";

const ReaderNewsDetails = () => {
  const [ NewsDetails, setNewsDetails] = React.useState({});
  const { loading, setLoading } = React.useContext(AppContext);
  const [ ShareImg, setShareImg ] = React.useState({});
  const { id } = useParams();

  const date = NewsDetails.timestart?.slice(0, 10);
  const time = NewsDetails.timestart?.slice(11, 16);

  const options = { month: "long", day: "numeric", year: "numeric" };
  const convertDate = new Date(date).toLocaleDateString("sv-SV", options);

  let currentIndex;
  let setCurrentIndex;

  React.useEffect(() => {
    getAdDetails(setNewsDetails, setLoading, id);
    getShareImg(setShareImg, "news", id);
  }, [id]);

  return (
    <div className="news-details">

    <PortalHelmet title={NewsDetails.title} image={ShareImg} />

      { loading ? (
        <Loading />
      ) : (
        
          <div
            className="advertiser-details-ad d-flex flex-column gap-3 mt-3"
            key={NewsDetails.id}
          >
            {NewsDetails.medias?.length > 1 ? (
              <ImageSlider
                slides={NewsDetails.medias}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                id={NewsDetails.id}
              />
            ) : (
              <>
                <img
                  src={`https://cdn.lokal.app/uploads/${NewsDetails.medias?.map(
                    (media) => media.src
                  )}`}
                  alt=""
                  className="advertiser-details-ad-img w-100"
                />
                {NewsDetails.medias?.map(
                  (media) =>
                    media.description?.length > 0 && (
                      <div className="fs-4 fst-italic " key={media.id}>
                        {media.description}
                      </div>
                    )
                )}
              </>
            )}
    
            <div className="fs-2 fw-bolder lh-sm">
              {NewsDetails.title}
            </div>
              <div className="fs-5 text-muted">
                {date} {time}
              </div>
            <div
              className="fs-5 lh-sm"
              dangerouslySetInnerHTML={{ __html: NewsDetails.body }}
            ></div>

          <Byline byline={NewsDetails.byline_content} />         

          </div>

      )}
    </div>
  );
  
};

export default ReaderNewsDetails;
