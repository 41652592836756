import React from "react";
import flowerImg from "../../../../public/images/flower.png";

const TodayFlowerItem = ({body, src}) => {
  return (
    <div className="row">
      <div className="col-9 fs-5" dangerouslySetInnerHTML={{__html: body}}></div>
      <div className="col-3 img-max">
        <img src={src} alt="" className=" rounded today-flower-item-img" />
      </div>
    </div>
  );
};

export default TodayFlowerItem;
