import React from "react";
import { getPhotosData } from "../../../../../api/Photos";
import { AppContext } from "../../../../../context/Data";

const PhotosLogic = () => {
  const [photosData, setPhotosData] = React.useState([]);
  const { loading, setLoading } = React.useContext(AppContext);

  React.useEffect(() => {
    getPhotosData(setPhotosData, setLoading);
  }, []);

  return { photosData, loading };
};

export default PhotosLogic;
