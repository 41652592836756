import React, { useEffect } from "react";
import readerNewsImg from "../../../../public/images/nyheter.png";
import NewsForm from "./NewsForm";
import { useNavigate } from "react-router-dom";
import "./NewsFormPage.css";
import PageHederImage from "../../UI/HeaderImage";

const NewsFormPage = () => {

  const [sending, setSending] = React.useState(false);

  useEffect(() => {
    setSending(0);
 }, []);


  const navigate = useNavigate();
  return (
    <div className="event-form-page">

    <PageHederImage image={readerNewsImg} text="Nyheter" />

      <div className="fw-bold fs-3 text-uppercase mt-3">skicka in nyhetstips</div>
      <div className="fs-5 mb-5">
        Skicka in ditt nyhetstips till oss.
      </div>
      { sending == 1 ? (
      <div class="alert alert-primary fs-5 mt-5" role="alert">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
          Skickar...
      </div>) : null}
      { sending == 2 ? (
      <div class="alert alert-primary fs-5 mt-5" role="alert">Tack för ditt bidrag, vi kommer att behandla det så fort vi kan.</div>) : null}
      { sending == 0 ? (<NewsForm setSending={setSending} />) : null}
    </div>
  );
};

export default NewsFormPage;
