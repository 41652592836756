import axios from "axios";
import { getAppId } from "./getAppId";

export const getCorporates = async (setCorporateData, setLoading) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://cdn.lokal.app/api/sectionforweb/1?app_id=" + getAppId(),
  })
    .then((res) => {
      setCorporateData(res.data);
      //   setSections(res.data.sections);
      setLoading(false);
      console.log(res.data);
      console.log(res.data.sections);
    })
    .catch((e) => {
      console.log(e);
    });
};


