import React from "react";
import "external-svg-loader";

const TraficInfoItem = ({
  messageCode,
  severity,
  locationDescription,
  message,
  startTime,
  endTime,
  iconId,
}) => {

  let iconUrl =
    "https://api.trafikinfo.trafikverket.se/v1/icons/" + iconId + "?type=svg";

  return (
    <div className="trafic-info-item d-flex flex-column">
      <div className="fw-bold fs-4">
        {messageCode} ({severity})
      </div>
      <div className="trafic-info-item-body d-flex justify-content-between">
        <div className="info">
          <div className="fs-5">{locationDescription}</div>
          <div className="fs-5">{message}</div>
          <div className="fs-5">Startade : {startTime}</div>
          <div className="fs-5">Slutar : {endTime}</div>
        </div>
        <div>
          <svg data-src={iconUrl} width="100px" height="100px" />
        </div>
      </div>
    </div>
  );
};

export default TraficInfoItem;
