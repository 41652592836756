import React from "react";
import FamilyNewsItem from "../family_news/FamilyNewsItem";
import familyNewsImg from "../../../../public/images/family.png";
import { useNavigate, useParams } from "react-router-dom";
import { getFamilyNewsDetails } from "../../../../api/FamilyNews";
import { AppContext } from "../../../../context/Data";
import Loading from "../../UI/Loading";
import PageHederImage from "../../UI/HeaderImage";

const FamilyNewsDetails = () => {
  const [familyNewsDetails, setFamilyNewsDetails] = React.useState({});
  const { loading, setLoading } = React.useContext(AppContext);
  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    getFamilyNewsDetails(setFamilyNewsDetails, setLoading, params.id);
  }, []);

  return (
    <div className="family-news-details family-news d-flex flex-column gap-3 global-padding">
      <PageHederImage image={familyNewsImg} text="Familjenyheter" />
      {loading ? (
        <Loading />
      ) : (
        <FamilyNewsItem
          details={true}
          title={familyNewsDetails.title}
          body={familyNewsDetails.body}
          src={`https://cdn.lokal.app/uploads/${familyNewsDetails.medias?.map(
            (item) => item.src
          )}`}
        />
      )}
      <div className="d-flex flex-column align-self-end">
        <button
          className="btn fs-6 text-uppercase fw-bold "
          onClick={() => navigate("/familynewsform")}
        >
          skicka in familjenyhet {">"}
        </button>
      </div>
    </div>
  );
};

export default FamilyNewsDetails;
