import React from "react";
import WeatherHour from "./WeatherHour";
import WeatherSource from "./WeatherSource";

const WeatherHours = ({ hours, service }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [services, setServices] = React.useState([]);

  const getHourId = (id) => {
    let services = hours.find((hour) => hour.id === id).services;
    setIsOpen((open) => ({
      ...open,
      [id]: !open[id],
    }));
    setServices(services);
    console.log(services);
  };

  return (
    <table className="table table-borderless weather-hours">
      <thead className="weather-hours-titles text-center">
        <tr>
          <th className="weather-hour-title text-uppercase fs-6">tid</th>
          <th className="weather-hour-title text-uppercase fs-6">väder</th>
          <th className="weather-hour-title text-uppercase fs-6">temperatur</th>
          <th className="weather-hour-title text-uppercase fs-6">nederbörd</th>
          <th className="weather-hour-title text-uppercase fs-6">vind</th>
          <th className="weather-hour-title text-uppercase fs-6">träffsäkerhet</th>
        </tr>
      </thead>

      {hours?.map((hour, index) => (
        <tbody key={hour.id}>
          <WeatherHour
            hour={hour}
            onClick={() => getHourId(hour.id)}
            key={hour.id}
          />
          {isOpen[hour.id] && (
            <>
              <tr className="">
                <th></th>
                <th
                  className="text-uppercase border-bottom border-3  fs-6"
                  colSpan={4}
                  align=""
                >
                  Väderkällor
                </th>
              </tr>
              {services.map((service) => (
                <WeatherSource
                  key={service.id}
                  icon={`https://cdn.lokal.app/imgs/${service.type}.png`}
                  temp={service.t}
                  rain={service.r}
                  wind={service.ws}
                  service={service.service}
                />
              ))}
              <tr>
                <th></th>
                <th colSpan={4} className="border-bottom border-3"></th>
              </tr>
            </>
          )}
        </tbody>
      ))}
    </table>
  );
};

export default WeatherHours;
