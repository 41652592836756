import React from "react";
import { getDeadAds } from "../../../../../api/DeadAds";
import {AppContext} from "../../../../../context/Data";

const DeadAdsLogic = () => {
  const [deadAdsData, setDeadAdsData] = React.useState([]);
  const {loading, setLoading} = React.useContext(AppContext);

  React.useEffect(() => {
    getDeadAds(setDeadAdsData, setLoading);
  }, []);
  return { deadAdsData, loading };
};

export default DeadAdsLogic;
