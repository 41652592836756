import React from 'react'
import homeStayImg from "../../../../public/images/homestay.png"
import HomeStayForm from './HomeStayForm';

const HomeStay = () => {
  return (
    <div className="event-form-page d-flex flex-column gap-3 container">
      <div className="events-header">
        <img src={homeStayImg} alt="" className="w-100" />
      </div>
      <h1 className="fw-bold text-uppercase">Skicka in hemestertips</h1>
      <div className="fs-3 mb-5">
        Bidra med dina bäst utflyktsmål på hemmaplan. Skriv en text som
        beskriver utflyktsmålet, lägg till egna bilder (endast bilder som du
        själv tagit och därmed har upphovsrätt till), placera ut platsen på
        kartan och välj vilka kategorier utflyktsmålet tillhör.
      </div>
      <HomeStayForm />
    </div>
  );
}

export default HomeStay