import React from 'react'
import { getDomainToByline } from '../../../api/App';
import './style/byline.css'

const Byline = (byline) => {

  let byline_str = byline.byline;

  const [ bylineWithDomain, setDomain] = React.useState([]);

  React.useEffect(() => {
    getDomainToByline(byline_str, setDomain);
  }, [])

  return (
    <>
    
    {(!bylineWithDomain) ? (null) : (<div
      className="byline fs-5 lh-sm"
      dangerouslySetInnerHTML={{ __html: bylineWithDomain.toString() }}
    ></div>)}

      </>
  )
}

export default Byline