import axios from "axios";
import { getAppId } from "./getAppId";

export const getTraficInfo = async (setTraficInfoData, setLoading) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/trafic?app_id=" + getAppId(),
  })
    .then((res) => {
      setTraficInfoData(res.data);
      setLoading(false);
      console.log(res.data);
    })
    .catch((e) => {
      console.log(e);
    });
};
