import React from "react";
import moment from "moment";
import "moment/locale/sv";
import Image from "../UI/Image";

const AdvertiserNewsHome = ({ medias, title, body, id, onClick, date }) => {
  const dateToDisplay = moment
    .utc(date)
    .local("sv")
    .startOf("seconds")
    .fromNow();
  return (
    <div
      className="advertiser-news-home"
      onClick={onClick}
    >
      <div className="row">
        <div className="col-4 img-max">
          <Image medias={medias} type="thmb" />
        </div>
        <div className="col-8">
          <div className="fs-3 fw-superbold lh-sm">
            {title}
          </div>
          <div
            className="fs-5 lines-4 lh-sm"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
        </div>
      </div>
      <div className="fs-6 fst-italic text-muted">{dateToDisplay}</div>
    </div>
  );
};

export default AdvertiserNewsHome;
