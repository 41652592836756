import React from 'react'
import { useParams } from 'react-router-dom';
import { getPageTxt } from '../../../../api/Page';
import Loading from '../../UI/Loading';
import PortalHelmet from '../../UI/PortalHelmet';

const AboutUs = () => {

  const { code } = useParams();
  const [txtData, setTxtData] = React.useState([]);
  const [loading, setLoading] = React.useState([]);

  React.useEffect(() => {
    getPageTxt(setTxtData, setLoading, code);
  }, [code]);

  return (

    <div className="">

      {loading ? (
        <Loading />
      ) : (
        <>
          <PortalHelmet title={txtData?.title} />
          <div className="fs-3 fw-bold">{txtData?.title}</div>
          <div className="fs-5" dangerouslySetInnerHTML={{ __html: txtData?.txt }} ></div>
        </>
      )}

      </div>

  )
}

export default AboutUs