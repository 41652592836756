import axios from "axios";
import { getAppId } from "./getAppId";

export const getPageTxt = async (setTxtData, setLoading, code) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/portal/textforweb/" + code + "?app_id=" + getAppId(),
  })
    .then((res) => {
      setTxtData(res.data);
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
    });
};
