import React from "react";
import todayFlowerImg from "../../../../public/images/flower-header-img.png";
import TodayFlowerLogic from "./logic/TodayFlowerLogic";
import TodayFlowerItem from "./TodayFlowerItem";
import Loading from "../../UI/Loading";
import "./TodayFlower.css";
import PageHederImage from "../../UI/HeaderImage";

const TodayFlower = () => {
  const { todaysFlowersData, loading } = TodayFlowerLogic();
  return (
    <div className="today-flower">
      
      <PageHederImage image={todayFlowerImg} text="Dagens ros" />

      <div className="mt-3">

      {loading ? (
        <Loading />
      ) : (
        todaysFlowersData?.map((item, i) => (
          <div key={item.id}>
            <TodayFlowerItem
              body={item.body}
              src={`https://cdn.lokal.app/uploads/${item.medias?.map(
                (item) => item.src_thmb
              )}`}
            />
            <hr className="border border-1 border-dark" />
          </div>
        ))
      )}
      </div>
    </div>
  );
};

export default TodayFlower;
