import React from "react";
import Image from "../../UI/Image";

const PhotosItem = ({ medias, body }) => {

  return (
    <div className="photos-item d-flex flex-column">
      <div className="img-max">
        <Image medias={medias} type={'src'} />
      </div>
      <div
        className="photos-item-body fs-5"
        dangerouslySetInnerHTML={{ __html: body }}
      ></div>
    </div>
  );
};

export default PhotosItem;
