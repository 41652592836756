import React from "react";
import { useNavigate } from "react-router-dom";
import readerNewsImg from "../../../../public/images/readernews.png";
import AdvertiserNews from "../../advertiser/AdvertiserNews";
import PageHederImage from "../../UI/HeaderImage";
import Loading from "../../UI/Loading";
import ReaderNewsLogic from "./logic/ReaderNewsLogic";
import "./ReaderNews.css";

const ReaderNews = () => {
  const { readerNewsData, loading } = ReaderNewsLogic();

  const navigate = useNavigate();
  return (
    <div className="reader-news d-flex flex-column gap-5 global-padding">

      <PageHederImage image={readerNewsImg} text="Läsarnyhet" />

      {loading ? (
        <Loading />
      ) : (
        <div className="news row">
          {readerNewsData?.map((item) => (
            <AdvertiserNews
              medias={item.medias}
              title={item.title}
              body={item.shortbody}
              id={item.id}
              key={item.id}
              onClick={() => navigate(`/readernews/${item.id}`)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ReaderNews;
