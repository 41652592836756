import React from "react";
import { useNavigate } from "react-router-dom";
import Image from "../UI/Image";

const AdvertiserInternalAd = ({ title, body, medias, media = null, customer_id }) => {

  const navigate = useNavigate();

  const handleClick = () => {


    if (customer_id == -11){
      navigate(`/weather`);
    }
    else if (customer_id == -12){
      navigate(`/todaysflowers`);
    }
    else if (customer_id == -2){
      navigate(`/freejobs`);
    }    

  }

  console.log("*******************");
  console.log(title);
  console.log(body);
  console.log("*******************");

  return (
    <div className="" onClick={handleClick} role="button">

      <div className="row">
        <div className="col-8">
          <div className="title fs-4 fw-bold lh-sm">{title}</div>
          <div
            className="fs-5 lines-4"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
        </div>
        <div className="col-4 img-max">
          { media ? (
          <Image medias={media} type="absolute" />
          ) : (
          <Image medias={medias} type="thmb" />
          )}
        </div>
      </div>
      <hr className="border border-1 border-dark" />
    </div>
  );
};

export default AdvertiserInternalAd;
