import React from "react";
import CompanyAd from "./CompanyAd";
import "./Ads.css";
import AssociationAd from "./AssociationAd";

const Ads = (props) => {
  return (
    <div
      className={`ads ${
        props.side === "right" && "border border-1 border-dark"
      }`}
    >
      {props.adType === "association" ? (
        <div className={`d-flex association-ads ${props.page === "associationLife" && "flex-column gap-3"}`}>
          <AssociationAd header={props.header} page={props.page} />
          <AssociationAd header={props.header} page={props.page} />
        </div>
      ) : (
        <CompanyAd header={props.header} side={props.side} />
      )}
    </div>
  );
};

export default Ads;
