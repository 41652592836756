import React from "react";
import { useNavigate } from "react-router-dom";
import "./workAd.css";
import { getSpecialAd } from "../../../../../api/SpecialAd";
import Image from "../../../UI/Image";
import CustomerName from "../../../UI/CustomerName";

const WorkAd = () => {

  const [ AdData, setAdData] = React.useState([]);
  const [ loading, setLoading] = React.useState();

  const navigate = useNavigate();

  const navigateOnClick = () => {
    navigate(`/freejobs`);
  }

  React.useEffect(() => {
    getSpecialAd(setAdData, setLoading, "Work");
  }, []);

  return (
    <>

      { loading ? ( null ) : (

        AdData?.id ? (<div className="rightSideBar-box grayBackground p-3" onClick={navigateOnClick} role="button">
          <div className="text-uppercase fs-6 fw-bold text-muted">Lediga jobb</div>
          <div className="row gx-2">
            <div className="col-8">
              <div className="fs-5 fw-bold lh-sm mb-2 mt-2">{AdData.title}</div>
              <div className="fs-6 lh-sm lines-4" dangerouslySetInnerHTML={{ __html: AdData.body }} ></div>
            </div>
            <div className="col-4">
              <Image medias={AdData.media} type={'thmb'} />
            </div>
          </div>
          <div className="mt-2">
            <CustomerName customer={AdData.customer}/>
          </div>
        </div>) : (<></>))

      }
    </>
  );

};

export default WorkAd;
