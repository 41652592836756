import React from "react";
//Title
//vatten temp
//luft temp
//luft fukt
// last updated
const PoolTemperatureItem = ({ name, humidity, airTemp, waterTemp, time }) => {
  return (
    <div className="pool-temp-item">
      <div className="fw-bold fs-4">{name}</div>
      <div className="names-values d-flex gap-3 fs-5">
        <div className="names">
          <div>Vattentemperatur:</div>
          <div>Lufttemperatur:</div>
          <div>Luftfuktighet:</div>
        </div>
        <div className="values d-flex flex-column align-items-center">
          <div className="value fw-bold">{waterTemp}°C</div>
          <div className="value">{airTemp}°C</div>
          <div className="value">{humidity}%</div>
        </div>
      </div>

      <div className="last-updated fs-6 fw-bold">Senaste uppdaterad {time}</div>
      <hr className="line" />
    </div>
  );
};

export default PoolTemperatureItem;
