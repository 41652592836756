import React from "react";
import electricityImg from "../../../../public/images/lunch.png";
import HederImage from "../../UI/HeaderImage";
import Loading from "../../UI/Loading";
import ElectricityLogic from "./logic/ElectricityLogic";
import "./Electricity.css";
import ExternalAd from "../../UI/externalAd/externalAd";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2/dist';
import PortalHelmet from "../../UI/PortalHelmet";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const Electricity = () => {
  const { electricityData, electricityProductionData, loading, loadingProduction } = ElectricityLogic();
 
  return (
    <div className="elictricity">


      <PortalHelmet title={'Elpriser'} />
      <HederImage image={electricityImg} text="Elpriser" />



      { (loading) ? (
        <Loading />
      ) : (

        <div>
      <div className="mt-3 mb-3">

        Priserna som visas är ifrån elområde {electricityData.area} och är inklusive skatt, moms och en normal nätavgift. Alltså så mycket som du troligvis kommer att betala.


        </div>

       <Chart type='bar' data={electricityData.chart} />

      <div className="mt-3 mb-3">
        <ExternalAd format="middle" />
      </div>

       <table className="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" className="text-center">Elpriset idag</th>
          <th scope="col" className="text-center">Elpriset imorgon </th>
        </tr>
      </thead>
 
      <tbody>

       { electricityData.data.map((row, index) => (


          <tr key={index}>
            <th scope="row">kl. {row.name}</th>
            <td className="text-center">{row.price} kr per kWh</td>
            <td className="text-center">{(row.tomorrow) ? (<>{row.tomorrow} kr per kWh</>) : (null)}</td>
          </tr>



       ))}
       </tbody>
       </table>
      </div>

        
  )
          }
  </div>
  )
};

export default Electricity;
