import React from "react";
import corporateImg from "../../../../public/images/corporate.png";
import associationImg from "../../../../public/images/association.png";
import churchImg from "../../../../public/images/church.png";
import watchAndDoImg from "../../../../public/images/seogora.png";
import Loading from "../../UI/Loading";
import AdvertiserItem from "./AdvertiserItem";
import AdvertiserLogic from "./logic/AdvertiserLogic";
import "./Advertiser.css";
import PageHederImage from "../../UI/HeaderImage";

const Advertiser = () => {
  const {
    advertiserData,
    active,
    loading,
    section,
    location,
    getSectionOnClick,
    showAllCorporatesOnClick,
  } = AdvertiserLogic();

  let image = location.pathname;

  return (
    <div className="advertiser">

    <PageHederImage image={image.includes("corporate") ? corporateImg : image.includes("association") ? associationImg : image.includes("church") ? churchImg : watchAndDoImg} text={advertiserData.name} />

      <div className="sections d-flex gap-3 flex-wrap mt-5 mb-5">
        <button
          className={`section-btn fs-6 ${active === "All" ? "active" : ""}`}
          onClick={() => showAllCorporatesOnClick()}
        >
          Alla {advertiserData.name}
        </button>
        {advertiserData.sections?.length > 1
          ? advertiserData.sections?.map((section, i) => (
              <button
                className={`section-btn fs-6 ${
                  active === section.name ? "active" : ""
                }`}
                onClick={() => getSectionOnClick(section.id)}
                key={section.id}
              >
                {section.name}
              </button>
            ))
          : null}
      </div>
      {loading ? (
        <Loading />
      ) : section.length <= 0 ? (
        advertiserData.customers?.map((customer, i) => (
          <div key={customer.id}>
            <AdvertiserItem
              name={customer.name}
              logo={`https://cdn.lokal.app/uploads/${customer.logo}`}
              id={customer.id}
            />
            <hr className="border border-1 border-dark" />
          </div>
        ))
      ) : (
        section.length > 0 &&
        advertiserData.sections
          .filter((item) => item.name.includes(section))
          .map((section) =>
            section.customers.map((customer) => (
              <div key={customer.id}>
                <AdvertiserItem
                  name={customer.name}
                  logo={`https://cdn.lokal.app/uploads/${customer.logo}`}
                  id={customer.id}
                />
                <hr className="border border-1 border-dark" />
              </div>
            ))
          )
      )}
    </div>
  );
};

export default Advertiser;
