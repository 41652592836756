import React from "react";
import { getPoolTemp } from "../../../../api/PoolTemp";
import residenceImg from "../../../../public/images/temperatur.jpg";
import "./PoolTemperature.css";
import PoolTemperatureItem from "./PoolTemperatureItem";
import { AppContext } from "../../../../context/Data";
import Loading from "../../UI/Loading";
import PageHederImage from "../../UI/HeaderImage";

const PoolTemperature = () => {
  const [poolTempdata, setPoolTempData] = React.useState([]);
  const { loading, setLoading } = React.useContext(AppContext);

  React.useEffect(() => {
    getPoolTemp(setPoolTempData, setLoading);
  }, []);
  return (
    <div className="pool-temp">

    <PageHederImage image={residenceImg} text="Badtemperatur" />

    <div className="mt-3">

      {loading ? (
        <Loading />
      ) : (
        poolTempdata?.map((item) => (
          <PoolTemperatureItem
            name={item.name}
            waterTemp={item.temp_water}
            airTemp={item.temp_air}
            humidity={item.humid}
            time={item.time.slice(0, 16)}
          />
        ))
      )}
    </div>
    </div>
  );
};

export default PoolTemperature;
