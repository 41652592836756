import React from "react";

//God jul , efter jul open from 27 from 30, efter det stänger en vecka

const WeatherSource = ({ icon, temp, rain, wind, service }) => {
  return (
    <>
      <tr className="">
        <td></td>
        <td align="center" className="text-uppercase  fs-6">
          {service}
        </td>
      </tr>
      <tr className="weather-source text-center">
        <td valign="middle"></td>
        <td valign="middle">
          <img src={icon} alt="" className="weather-icon" />
        </td>
        <td valign="middle" className="fs-6">{temp}°C</td>
        <td valign="middle" className="fs-6">{rain.toFixed(2)} mm</td>
        <td valign="middle" className="fs-6">{wind} m/s</td>
      </tr>
    </>
  );
};

export default WeatherSource;
