import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
import ScrollToTopAndTracking from "./components/UI/ScrollToTop";
import { routes } from "../routes/routes";
import Data from "../context/Data";
import PortalHelmet from "./components/UI/PortalHelmet";
import { CookiesProvider } from 'react-cookie';

const App = () => {

  const routeComponents = routes.map((route, index) => (
    <Route
      path={route.path}
      element={<Layout>{route.component}</Layout>}
      key={index}
    />
  ));

  return (
    <div className="app">
      <CookiesProvider>
        <PortalHelmet />
        <Data>
          <BrowserRouter>
            <ScrollToTopAndTracking />
            <Routes>{routeComponents}</Routes>
          </BrowserRouter>
        </Data>
      </CookiesProvider>
    </div>
  );
};

export default App;
