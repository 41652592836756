import React from "react";
import { useParams } from "react-router-dom";
import { getAdDetails } from "../../../../api/AdDetails";
import { AppContext } from "../../../../context/Data";
import readerNewsImg from "../../../../public/images/readernews.png";
import AdvertiserAd from "../../advertiser/AdvertiserAd";
import Loading from "../../UI/Loading";
import "./ReaderNewsDetails.css";

const ReaderNewsDetails = () => {
  const [readerNewsDetails, setReaderNewsDetails] = React.useState({});
  const { loading, setLoading } = React.useContext(AppContext);
  const { id } = useParams();

  const date = readerNewsDetails.timestart?.slice(0, 10);
  const time = readerNewsDetails.timestart?.slice(11, 16);

  console.log(date);

  const options = { month: "long", day: "numeric", year: "numeric" };
  const convertDate = new Date(date).toLocaleDateString("sv-SV", options);

  console.log(convertDate);

  React.useEffect(() => {
    getAdDetails(setReaderNewsDetails, setLoading, id);
  }, [id]);
  return (
    <div className="reader-news-details global-padding d-flex flex-column gap-3">
      <img src={readerNewsImg} alt="" className="w-100" />
      <div className="fs-4 fst-italic reader-news-details-header-text">
        Detta är en läsarnyhet. Bidra till SävsjöAppen genom att själv skicka in
        läsarnyheter.
      </div>
      {loading ? (
        <Loading />
      ) : (
        <AdvertiserAd
          data={readerNewsDetails}
          typeOf="object"
          place="readernewsdetails"
          date={convertDate}
          time={time}
        />
      )}
    </div>
  );
};

export default ReaderNewsDetails;
