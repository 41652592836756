export const getAppId = () => {

    let app_id = 1;

    switch (window.location.hostname) {
        case 'localhost':
        case 'savsjo.appen.se':
          app_id = 1;
          break;
        case 'vetlanda.appen.se':
            app_id = 2;
            break;  
        case 'nassjo.appen.se':
          app_id = 3;
          break;  
        case 'eksjo.appen.se':
          app_id = 5;
          break;                            
        case 'hagfors.appen.se':
          app_id = 30;
          break;
        case 'orkelljunga.appen.se':
          app_id = 36;
          break;     
        case 'ljungby.appen.se':
          app_id = 35;
          break;     
        case 'alvesta.appen.se':
          app_id = 16;
          break;                                
        default:
          app_id = 0;
      }

    return app_id;

}