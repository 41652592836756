import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { getAdvertiserDetails } from "../../../../../api/Advertiser";
import { AppContext } from "../../../../../context/Data";

const AdvertiserDetailsLogic = () => {
  const { loading, setLoading } = React.useContext(AppContext);
  const [advertiserDetailsData, setAdvertiserDetailsData] = React.useState({});
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const params = useParams();

  React.useEffect(() => {
    if (params.id > 0) {
      getAdvertiserDetails(setAdvertiserDetailsData, setLoading, params.id);
    }
  }, []);
  return { loading, advertiserDetailsData, currentIndex, setCurrentIndex };
};

export default AdvertiserDetailsLogic;
