import axios from "axios";
import { getAppId } from "./getAppId";

export const getNews = async (setNewsData, setLoading) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/adsforweb?c_id=-1&app_id=" + getAppId(),
  })
    .then((res) => {
      setNewsData(res.data);
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
    });
};
