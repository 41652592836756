import React from "react";
import { getTraficInfo } from "../../../../../api/TraficInfo";
import {AppContext} from "../../../../../context/Data";

const TraficInfoLogic = () => {
  const [ traficInfoData, setTraficInfoData] = React.useState([]);
  const [ longitude, setLongitude] = React.useState([]);
  const [ latitude, setLatitude] = React.useState([]);
  const [ loading, setLoading ] = React.useState([]);

  React.useEffect(() => {
    try {
      getTraficInfo(setTraficInfoData, setLoading);
      getCordinates(setLongitude, setLatitude);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return { traficInfoData, longitude, latitude, loading };
};

export default TraficInfoLogic;
