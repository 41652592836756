import axios from "axios";
import { getAppId } from "./getAppId";

export const getPlaces = async (setPlaces, setLoading) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/app/" + getAppId(),
  })
    .then((res) => {
      setPlaces(res.data.places);
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getWeatherData = async (setWeatherData, setLoading, id) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: `https://api.lokal.app/api/weather/forecastforweb/${id}`,
  })
    .then((res) => {
      setWeatherData(res.data);
      setLoading(false);
    })
    .catch((e) => {
      console.log(e);
    });
};
