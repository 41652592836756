import React from "react";
import { FiExternalLink } from "react-icons/fi";

const ResidenceForSaleItem = (props) => {
  

  return (
    <div className="free-jobs-item d-flex gap-3">
      <div className="residence-item-img">
        <img src={props.img} alt="" className="residence-img rounded-3" />
      </div>
      <div className="residence-item-body fs-3 d-flex flex-column">
        <div className="fw-bold fs-5">{props.type}</div>
        <div className="d-flex flex-column flex-grow-1">
          <div className="fs-4">{props.price}</div>
          <div className="fs-5">
            {props.adress}, {props.place}
          </div>
          <div className="fs-5">Publicerad : {props.published}</div>
        </div>
      </div>
      <div className="residence-item-icon-div ms-auto align-self-end">
        <a href={props.url} target="_blank">
          <FiExternalLink className="residence-item-icon" />
        </a>
      </div>
    </div>
  );
};

export default ResidenceForSaleItem;
