import React from "react";

const AdvertiserOpening = ({ data }) => {
  return (
    <div className="advertiser-details-opening-times">
      <h5 className="fw-bold">Öppettider</h5>
      <h5
        className=""
        dangerouslySetInnerHTML={{
          __html: data.profile_openhours,
        }}
      ></h5>
    </div>
  );
};

export default AdvertiserOpening;
