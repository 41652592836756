import React from "react";
import lunchImg from "../../../../public/images/lunch.png";
import HederImage from "../../UI/HeaderImage";
import Loading from "../../UI/Loading";
import LunchLogic from "./logic/LunchLogic";
import "./Lunch.css";
import moment from "moment";
import "moment/locale/sv";
import Image from "../../UI/Image";
import PortalHelmet from "../../UI/PortalHelmet";
import ExternalAd from "../../UI/externalAd/externalAd";


const Lunch = (props) => {
  const { lunchData, loading } = LunchLogic();

  return (
    <div className="lunch">
      
      <PortalHelmet title={'Dagens lunch'} />
      <HederImage image={lunchImg} text="dagens lunch" />

      {loading ? (
        <Loading />
      ) : (
        <>
          { lunchData.map((lunchDay, index) => (
            <>



            {lunchDay["data"].length > 0 && (

          <><div className="fw-bold fs-3 mt-4 mb-5 text-uppercase" key={"lunch_" + index}>
              {moment.utc(lunchDay["date"]).local("sv").format("dddd Do MMMM")}
            </div>

            {lunchDay["data"].map((lunch, index2) => (

              <>

            { (index == 0 && index2 == 1) && (<div className="mb-3"><ExternalAd format="middle" /></div>)}
            { (index == 2 && index2 == 1) && (<div className="mb-3"><ExternalAd format="middle" /></div>)}

              <div className="d-flex align-items-center gap-2">
                <div className="customer-img">
                  <img src={"https://cdn.lokal.app/uploads/" + lunch.logo} />
                </div>
                <div className="fw-bold fs-5">{lunch.customer_name}</div>
              </div>

              <div className="row mt-3">
                <div className="col-9">
                <div className={`fs-5 lh-sm`}
                  dangerouslySetInnerHTML={{ __html: lunch.body }}
                ></div>
                </div>
                <div className="col-3">
                  <Image medias={lunch.customer_media} type="thmb" />
                  </div>
              </div>

              <hr className="border border-1 border-dark" />

              </>

            ))}
            
            
            </>

            )}

            </>

          ))}
        </>

        
  )
          }
  </div>
  )
};

export default Lunch;
