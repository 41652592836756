import React from "react";
import idagImg from "../../../../public/images/idag.png";
import { AppContext } from "../../../../context/Data";
import Loading from "../../UI/Loading";
import "./Download.css";
import PageHederImage from "../../UI/HeaderImage";
import { getAppData, redirectAppDownload } from "../../../../api/App";
import iosImg from "../../../../public/images/swedish_get_ios.png"
import androidImg from "../../../../public/images/swedish_get.png";


const Download = () => {
  const [ appData, setAppData] = React.useState({});
  const { loading, setLoading } = React.useContext(AppContext);

  React.useEffect(() => {
    getAppData(setAppData, setLoading);
    
  }, []);

  redirectAppDownload()
  
  return (
    <div className="download">
      
      <PageHederImage image={idagImg} text={'Ladda ner ' + appData.name} />

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="fs-5 mt-3">{appData.name} finns som app både för Android och iOS.<br />Ladda ner den genom att söka på {appData.name} där appar finns eller klicka på länkarna här nedanför.</div>

          <br />

        <a href={appData.itunes_link}><img src={iosImg} alt="" /></a>
        <a href={appData.googleplay_link}><img src={androidImg} alt="" /></a><br />

        <div className="fs-5 mt-3">I {appData.name} finns allt innehåll som finns på webbsidan och utöver det Quiz med en ny tipspromenad varje vecka och {appData.shortName}kampen som är en frågesport med lokala frågor. 
        
        <div className="fs-5 mt-3"></div>I appen kan du välja att följa till exempel Nyheter, Dagens lunch, Väder, Dagens ros, enskilda företag och föreningar och få en notis när något nytt postas.</div>
          


        </>
      )}
    </div>
  );
};

export default Download;
