import axios from "axios";
import React, { useEffect } from "react";
import { getAppId } from "../../../../api/getAppId";
import flowerImg from "../../../../public/images/flower.png";
import "./TodayFlowerForm.css";


const TodayFlowerForm = ({ setSending }) => {

  const [img, setImg] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedImageBlob, setSelectedImageBlob] = React.useState(null);

  const [frm_name, setName] = React.useState("");
  const [frm_email, setEmail] = React.useState("");
  const [frm_mobileNumber, setMobileNumber] = React.useState("");
  const [frm_message, setMessage] = React.useState("");
  const [frm_date, setDate] = React.useState("");

  const today = new Date();
  const date = today.setDate(today.getDate() + 3); 
  const defaultDateValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd

  
  useEffect(() => {
    setImg(75363)
    setDate(defaultDateValue);
 }, []);

 const convertImage = (file) => {

 let reader = new FileReader();
 reader.onload = function(e) {
   setSelectedImageBlob(e.target.result);
 };
 reader.readAsDataURL(file);

}

  const postForm = () => {

    let postObject = {
      type: "web_rose",
      name: frm_name,
      email: frm_email,
      phone: frm_mobileNumber,
      text: frm_message,
      date: frm_date,
      app_id: getAppId()
    }

    if (selectedImage != null) {
      postObject.imageblob = selectedImageBlob;
    }
    else
    {
      postObject.image = img;
    }

    console.log(postObject);

    if (frm_message == "" || frm_name == "" || frm_email == "" || frm_mobileNumber == "" || frm_date == "") {
      return 
    }

    setSending(1);

    return axios({
      method: "post",
      url: "https://api.lokal.app/api/postform",
      data: postObject
    })
      .then((res) => {

        setSending(2);

      })




  }






  return (
    <div className="todat-flower-form">
      <form className="">
        <div className="">
          <div className="fs-5 fw-bold">1. Välj en bild eller ladda upp en egen</div>

          {selectedImage == null ? (

              <div className="input-images d-flex gap-3">
              <div className="row">
                  {img == 75363 ? (
                    <div className="col-4 selected"><img src="https://cdn.lokal.app/uploads/_src_thmb_6391ea428635f.jpeg" alt="" /></div>
                  ) : (
                    <div className="col-4" role="button" onClick={() => setImg(75363)}><img src="https://cdn.lokal.app/uploads/_src_thmb_6391ea428635f.jpeg" alt="" /></div>
                  )}
                  
                  {img == 82725 ? (
                    <div className="col-4 selected"><img src="https://cdn.lokal.app/uploads/_src_thmb_63bbdfa5ebee2.jpeg" alt="" /></div>
                  ) : (
                    <div className="col-4" role="button" onClick={() => setImg(82725)}><img src="https://cdn.lokal.app/uploads/_src_thmb_63bbdfa5ebee2.jpeg" alt="" /></div>
                  )}

                  {img == 109762 ? (
                    <div className="col-4 selected"><img src="https://cdn.lokal.app/uploads/_src_thmb_63bbe2db90d02.jpeg" alt="" /></div>
                  ) : (
                    <div className="col-4" role="button" onClick={() => setImg(109762)}><img src="https://cdn.lokal.app/uploads/_src_thmb_63bbe2db90d02.jpeg" alt="" /></div>
                  )}
                  
                  {img == 109767 ? (
                    <div className="col-4 mt-3 selected"><img src="https://cdn.lokal.app/uploads/_src_thmb_63bbe30628abc.jpeg" alt="" /></div>
                  ) : (
                    <div className="col-4 mt-3" role="button" onClick={() => setImg(109767)}><img src="https://cdn.lokal.app/uploads/_src_thmb_63bbe30628abc.jpeg" alt="" /></div>
                  )}
                  
                  {img == 109766 ? (
                    <div className="col-4 mt-3 selected"><img src="https://cdn.lokal.app/uploads/_src_thmb_63bbe2c53c598.jpeg" alt="" /></div>
                  ) : (
                    <div className="col-4 mt-3" role="button" onClick={() => setImg(109766)}><img src="https://cdn.lokal.app/uploads/_src_thmb_63bbe2c53c598.jpeg" alt="" /></div>
                  )}
                  
                  {img == 108157 ? (
                    <div className="col-4 mt-3  selected"><img src="https://cdn.lokal.app/uploads/_src_thmb_63907c52b07b9.jpeg" alt="" /></div>
                  ) : (
                    <div className="col-4 mt-3" role="button" onClick={() => setImg(108157)}><img src="https://cdn.lokal.app/uploads/_src_thmb_63907c52b07b9.jpeg" alt="" /></div>
                  )}
              </div>
              </div>

          ) : (
          
          <>
          <img alt="not fount" width={"250px"} src={URL.createObjectURL(selectedImage)} /><br />
          
          <button className="btn form-control btn-secondary mt-3" onClick={() => {setSelectedImage(null)}}>Radera bilden</button>
          </>        
          )}      

        </div>
        <div className="mb-3">
        <input
        type="file"
        name="myImage"
        className="form-control mt-3"
        accept="image/jpeg, image/jpg"
        onChange={(event) => {
          console.log(event.target.files[0]);
          setSelectedImage(event.target.files[0]);
          convertImage(event.target.files[0]);
        }}
      />
        </div>
        <div className="fs-5 fw-bold">2. Skriv en text</div>

        <div className="mb-3">
          <textarea
            rows="3"
            className="form-control form-input"
            placeholder="text"
            required
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="">

        <div className="fs-5 fw-bold">3. Välj önskad publiceringsdag</div>

            <input
              type="date"
              className="form-control form-input"
              id="datetime"
              name="datetime"
              required
              defaultValue={defaultDateValue}
              onChange={(e) => setDate(e.target.value)}
            />
        </div>
        <label className="form-label fs-5 fw-bold mt-5">
          Inskickad av (Publiceras ej)
        </label>
        <div className="mb-1">
          <input
            type="text"
            className="form-control form-input"
            id="fullname"
            placeholder="För- och efternamn"
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-1">
          <input
            type="email"
            className="form-control form-input"
            id="email"
            placeholder="E-postadress"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-1">
          <input
            type="tel"
            className="form-control form-input"
            id="tel"
            placeholder="Telefonnummer"
            required
            onChange={(e) => setMobileNumber(e.target.value)}
          />
        </div>
        <button
          className="btn form-control btn-secondary mt-3"
          type="button"
          onClick={postForm}
        >
          Skicka
        </button>
      </form>
    </div>
  );
};

export default TodayFlowerForm;
