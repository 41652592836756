import axios from "axios";
import React, { useEffect } from "react";
import { getAppId } from "../../../../api/getAppId";



const EventForm = (parent) => {

  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedImageBlob, setSelectedImageBlob] = React.useState(null);

  const [frm_name, setName] = React.useState("");
  const [frm_email, setEmail] = React.useState("");
  const [frm_mobileNumber, setMobileNumber] = React.useState("");
  const [frm_message, setMessage] = React.useState("");
  const [frm_date, setDate] = React.useState("");
  const [frm_title, setTitle] = React.useState("");
  const [frm_place, setPlace] = React.useState("");

  const today = new Date();
  const date = today.setDate(today.getDate() + 3); 
  const defaultDateValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd

  
  useEffect(() => {
    setDate(defaultDateValue);
    setSelectedImage(null);
 }, []);

 const convertImage = (file) => {

 let reader = new FileReader();
 reader.onload = function(e) {
   setSelectedImageBlob(e.target.result);
 };
 reader.readAsDataURL(file);

}

  const postForm = () => {

    let postObject = {
      type: "web_event",
      name: frm_name,
      email: frm_email,
      phone: frm_mobileNumber,
      text: frm_message,
      title: frm_title,
      place: frm_place,
      date: frm_date,
      imageblob: selectedImageBlob,
      app_id: getAppId()
    }

    console.log(postObject);

    if (frm_message == "" || frm_name == "" || frm_email == "" || frm_mobileNumber == "" || frm_date == "" || selectedImage == null) {
      return 
    }

    parent.setSending(1);

    return axios({
      method: "post",
      url: "https://api.lokal.app/api/postform",
      data: postObject
    })
      .then((res) => {

        parent.setSending(2);

      })


  }
  

  return (
    <div className="event-form">
    <form className="">
      <div className="">
        <div className="fs-5 fw-bold">1. Ladda upp en bild</div>

        {selectedImage == null ? ( null ) : (

          <>
          <img alt="not fount" width={"250px"} src={URL.createObjectURL(selectedImage)} /><br />

          <button className="btn form-control btn-secondary mt-3" onClick={() => {setSelectedImage(null)}}>Radera bilden</button>
          </>        
          )}  

      </div>
      <div className="mb-3">
      <input
      type="file"
      name="myImage"
      className="form-control mt-3"
      accept="image/jpeg, image/jpg"
      onChange={(event) => {
        console.log(event.target.files[0]);
        setSelectedImage(event.target.files[0]);
        convertImage(event.target.files[0]);
      }}
    />
      </div>
      <div className="fs-5 fw-bold">2. Beskriv eventet</div>

      <div className="mt-3 fs-6 fw-bold">Rubrik</div>
      <input type="text" className="form-control form-input" placeholder="Rubrik" required onChange={(e) => setTitle(e.target.value)} />

      <div className="mt-3 fs-6 fw-bold">Plats</div>
      <input type="text" className="form-control form-input" placeholder="Plats" required onChange={(e) => setPlace(e.target.value)} />

      <div className="mt-3 fs-6 fw-bold">Text</div>

      <div className="">
        <textarea
          rows="3"
          className="form-control form-input"
          placeholder="text"
          required
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      
      <div className="mt-3 fs-5 fw-bold">Tid</div>

          <input
            type="date"
            className="form-control form-input"
            id="datetime"
            name="datetime"
            required
            defaultValue={defaultDateValue}
            onChange={(e) => setDate(e.target.value)}
          />
      
      <label className="form-label fs-5 fw-bold mt-5">
        Inskickad av (Publiceras ej)
      </label>
      <div className="mb-1">
        <input
          type="text"
          className="form-control form-input"
          id="fullname"
          placeholder="För- och efternamn"
          required
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="mb-1">
        <input
          type="email"
          className="form-control form-input"
          id="email"
          placeholder="E-postadress"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mb-1">
        <input
          type="tel"
          className="form-control form-input"
          id="tel"
          placeholder="Telefonnummer"
          required
          onChange={(e) => setMobileNumber(e.target.value)}
        />
      </div>
      <button
        className="btn form-control btn-secondary mt-3"
        type="button"
        onClick={postForm}
      >
        Skicka
      </button>
    </form>
  </div>
  );

  
};

export default EventForm;
