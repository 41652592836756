import Data from "../context/Data";
import AboutUs from "../src/components/pages/about_us/AboutUs";
import Advertiser from "../src/components/pages/advertiser/Advertiser";
import AdvertiserDetails from "../src/components/pages/advertiser_details/AdvertiserDetails";
import AdvertiserUnderPage from "../src/components/pages/advertiser_details/AdvertiserUnderPage";
import AdPage from "../src/components/pages/ad_page/AdPage";
import AssociationLife from "../src/components/pages/association_life/AssociationLife";
import BuyAndSell from "../src/components/pages/buy_sell/BuyAndSell";
import QuizFormPage from "../src/components/pages/city_quiz/QuizFormPage";
import DeadAds from "../src/components/pages/dead_ads/DeadAds";
import Debate from "../src/components/pages/debate/Debate";
import Download from "../src/components/pages/download/Download";
import Electricity from "../src/components/pages/electricity/Electricity";
import Events from "../src/components/pages/events/Events";
import EventDetails from "../src/components/pages/event_details/EventDetails";
import EventFormPage from "../src/components/pages/event_form/EventFormPage";
import FamilyNews from "../src/components/pages/family_news/FamilyNews";
import FamilyNewsDetails from "../src/components/pages/family_news_details/FamilyNewsDetails";
import FamilyNewsFormPage from "../src/components/pages/family_news_form/FamilyNewsFormPage";
import FreeJobs from "../src/components/pages/free_jobs/FreeJobs";
import Home from "../src/components/pages/home/Home";
import HomeStay from "../src/components/pages/home_stay/HomeStay";
import Lunch from "../src/components/pages/lunch/Lunch";
import News from "../src/components/pages/news/News";
import NewsDetails from "../src/components/pages/news_details/NewsDetails";
import NewsFormPage from "../src/components/pages/news_form/NewsFormPage";
import Offers from "../src/components/pages/offers/Offers";
import Photos from "../src/components/pages/photos/Photos";
import PhotosFormPage from "../src/components/pages/photos_form/PhotosFormPage";
import PoliceNews from "../src/components/pages/police_news/PoliceNews";
import PoolTemperature from "../src/components/pages/pool_temperature/PoolTemperature";
import ReaderNews from "../src/components/pages/reader_news/ReaderNews";
import ReaderNewsDetails from "../src/components/pages/reader_news_details/ReaderNewsDetails";
import ReaderNewsForm from "../src/components/pages/reader_news_form/ReaderNewsForm";
import Register from "../src/components/pages/register/Register";
import Resa from "../src/components/pages/resa/Resa";
import ResaDetails from "../src/components/pages/resa_details/ResaDetails";
import ResidenceForSale from "../src/components/pages/residence_for_sale/ResidenceForSale";
import SoldResidence from "../src/components/pages/sold_residence/SoldResidence";
import Today from "../src/components/pages/today/Today";
import TodayFlower from "../src/components/pages/today_flower/TodayFlower";
import TodayFlowerFormPage from "../src/components/pages/today_flower_form/TodayFlowerFormPage";
import TraficInfo from "../src/components/pages/trafic_info/TraficInfo";
import Weather from "../src/components/pages/weather/Weather";



export const routes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/ad/:id",
    component: <AdPage />,
  },
  {
    path: "/lunch",
    component: <Lunch />,
  },
  {
    path: "/events",
    component: <Events />,
  },
  {
    path: "/events/:id",
    component: <EventDetails />,
  },
  {
    path: "/eventform",
    component: <EventFormPage />,
  },
  {
    path: "/eventtips",
    component: <EventFormPage />,
  },
  {
    path: "/offers",
    component: <Offers />,
  },
  {
    path: "/offers/:id",
    component: <Offers />,
  },
  {
    path: "/debate",
    component: <Debate />,
  },
  {
    path: "/buyandsell",
    component: <BuyAndSell />,
  },
  {
    path: "/todaysflowers",
    component: <TodayFlower />,
  },
  {
    path: "/todayflowersform",
    component: <TodayFlowerFormPage />,
  },
  {
    path: "/aboutus",
    component: <AboutUs />,
  },
  {
    path: "/photos",
    component: <Photos />,
  },
  {
    path: "photosform",
    component: <PhotosFormPage />,
  },
  {
    path: "/readernews",
    component: <ReaderNews />,
  },
  {
    path: "/readernews/:id",
    component: <ReaderNewsDetails />,
  },
  {
    path: "/readernewsform",
    component: <ReaderNewsForm />,
  },
  {
    path: "/news",
    component: <News />,
  },
  {
    path: "/news/:id",
    component: <NewsDetails />,
  },
  {
    path: "/nyhetstips",
    component: <NewsFormPage />,
  },
  {
    path: "/familynews",
    component: <FamilyNews />,
  },
  {
    path: "/familynews/:id",
    component: <FamilyNewsDetails />,
  },
  {
    path: "/familynewsform",
    component: <FamilyNewsFormPage />,
  },
  {
    path: "/homestay",
    component: <HomeStay />,
  },
  {
    path: "/corporate",
    component: (
      <Data>
        <Advertiser />
      </Data>
    ),
  },
  {
    path: "/association",
    component: <Advertiser />,
  },
  {
    path: "/church",
    component: <Advertiser />,
  },
  {
    path: "/watchdo",
    component: <Advertiser />,
  },
  {
    path: "advertiser/:id/advertiserunderpage",
    component: <AdvertiserUnderPage />,
  },
  {
    path: "/advertiser/:id",
    component: <AdvertiserDetails />,
  },
  {
    path: "/deadads",
    component: <DeadAds />,
  },
  {
    path: "/resa",
    component: <Resa />,
  },
  {
    path: "/resa/:id",
    component: <ResaDetails />,
  },
  {
    path: "/freejobs",
    component: <FreeJobs />,
  },
  {
    path: "/residenceforsale",
    component: <ResidenceForSale />,
  },
  {
    path: "/soldresidence",
    component: <SoldResidence />,
  },
  {
    path: "/traficinfo",
    component: <TraficInfo />,
  },
  {
    path: "/policenews",
    component: <PoliceNews />,
  },
  {
    path: "/weather",
    component: <Weather />,
  },
  {
    path: "/associationlife",
    component: <AssociationLife />,
  },
  {
    path: "/pooltemperature",
    component: <PoolTemperature />,
  },
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/today",
    component: <Today />,
  },
  {
    path: "/elprices",
    component: <Electricity />,
  },
  {
    path: "/download",
    component: <Download />,
  },
  {
    path: "/text/:code",
    component: <AboutUs />,
  },
  {
    path: "/quizform",
    component: <QuizFormPage />,
  },
];
