import React from "react";
import { useParams } from "react-router-dom";
import { getAdDetails, getShareImg } from "../../../../api/AdDetails";
import { getAdvertiserDetails } from "../../../../api/Advertiser";
import { AppContext } from "../../../../context/Data";
import AdvertiserAd from "../../advertiser/AdvertiserAd";
import AdvertiserAddress from "../../advertiser/AdvertiserAddress";
import AdvertiserBody from "../../advertiser/AdvertiserBody";
import AdvertiserContact from "../../advertiser/AdvertiserContact";
import AdvertiserNameLogo from "../../advertiser/AdvertiserNameLogo";
import AdvertiserOpening from "../../advertiser/AdvertiserOpening";
import AdvertiserPages from "../../advertiser/AdvertiserPages";
import Loading from "../../UI/Loading";
import PortalHelmet from "../../UI/PortalHelmet";
import "./AdPage.css";

const AdPage = () => {
  const [adDetails, setAdDetails] = React.useState({});
  const [advertiserDetails, setAdvertiserDetails] = React.useState({});
  const { setLoading, loading } = React.useContext(AppContext);
  const { id } = useParams();
  const [ ShareImg, setShareImg ] = React.useState({});

  React.useEffect(() => {
    getAdDetails(setAdDetails, setLoading, id);
    getShareImg(setShareImg, "ad", id);
    if (adDetails.customer_id > 0) {
      getAdvertiserDetails(
        setAdvertiserDetails,
        setLoading,
        adDetails.customer_id
      );
    }
  }, [id, adDetails.customer_id]);

  const contactsLength = advertiserDetails?.contacts?.length;

  return (
    <div className="ad-page d-flex flex-column gap-5">

      <PortalHelmet title={adDetails.title} image={ShareImg}/>

      {loading ? (
        <Loading />
      ) : (
        <AdvertiserAd data={adDetails} typeOf="object" />
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          <AdvertiserNameLogo data={advertiserDetails} />
          <AdvertiserBody data={advertiserDetails} />

          {advertiserDetails.profile_openhours?.length > 0 ? (
            <AdvertiserOpening data={advertiserDetails} />
          ) : null}

          {advertiserDetails.profile_address?.length > 0 ? (
            <AdvertiserAddress data={advertiserDetails} />
          ) : null}

          {advertiserDetails.profile_phone != null ||
          advertiserDetails.profile_email != null ? (
            <AdvertiserContact data={advertiserDetails} />
          ) : null}

          {contactsLength > 0 ? (
            <AdvertiserContact
              data={advertiserDetails}
              contactPersons="contactPersons"
            />
          ) : null}

          {advertiserDetails.profile_webb != null ||
          advertiserDetails.pages?.length > 0 ? (
            <AdvertiserPages data={advertiserDetails} />
          ) : null}
        </>
      )}
    </div>
  );
};

export default AdPage;
