import React, { useEffect } from 'react'
import familyNewsImg from "../../../../public/images/family.png";
import PageHederImage from '../../UI/HeaderImage';
import FamilyNewsForm from './FamilyNewsForm';


const FamilyNewsFormPage = () => {

  const [sending, setSending] = React.useState(false);

  useEffect(() => {
    setSending(0);
 }, []);

  return (
    <div className="family-news-page">
      <PageHederImage image={familyNewsImg} text="Familjenyheter" />
      
      <div className="fs-5 fw-bold text-uppercase">skicka in familjenyhet</div>
      <div className="fs-5 mb-5">
        Uppmärksamma födelsedagar, jubilarer och bröllopsdagar med en hälsning.
        Annonsera förlovning, vigsel, nyfödd och adoption i appen.
      </div>
      { sending == 1 ? (
      <div class="alert alert-primary fs-5 mt-5" role="alert">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
          Skickar...
      </div>) : null}
      { sending == 2 ? (
      <div class="alert alert-primary fs-5 mt-5" role="alert">Tack för ditt bidrag, vi kommer att behandla det så fort vi kan.</div>) : null}
      { sending == 0 ? (<FamilyNewsForm setSending={setSending} />) : null}

    </div>
  );
}

export default FamilyNewsFormPage