import React from "react";

export const AppContext = React.createContext({});

const Data = (props) => {
  const [loading, setLoading] = React.useState(true);
  return (
    <AppContext.Provider value={{ loading, setLoading }}>
      {props.children}
    </AppContext.Provider>
  );
};

export default Data;
