import React from "react";
import { Helmet } from "react-helmet";
import { getHeader } from "../../../api/App";
import { getAppId } from "../../../api/getAppId";
import { setHeader } from "../../helpers/setHeader";

const PortalHelmet = ({title, description, image}) => {

    let header = setHeader();

    if (title)
    {
        title = header + " - " + title;
    }
    else
    {
        title = header;
    }
    

  return (
    <Helmet>
        <title>{title}</title>
        <meta name="og:description" content={description} />
        <meta property="og:image" content={image} />
    </Helmet>
  );
};

export default PortalHelmet;