import React from "react";
import { getOffers } from "../../../../../api/Offers";
import { AppContext } from "../../../../../context/Data";

export const OffersLogic = () => {
  const [offersData, setOffersData] = React.useState([]);
  const { loading, setLoading } = React.useContext(AppContext);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    getOffers(setOffersData, setLoading);
  }, []);
  return { offersData, loading, currentIndex, setCurrentIndex };
};
