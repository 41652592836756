import React from "react";
import { FiExternalLink } from "react-icons/fi";

const PoliceNewsItem = (props) => {
  return (
    <div className="police-news-item d-flex gap-3" style={props.style}>
      <div className="police-news-item-body col-10">
        <h1 className="fw-bold ">{props.title}</h1>
        <p className="fs-2">{props.content}</p>
      </div>
      <div className="police-news-item-icon-div align-self-end ms-auto">
        <a href={props.url} target="_blank">
          <FiExternalLink className="police-news-item-icon" />
        </a>
      </div>
    </div>
  );
};

export default PoliceNewsItem;
