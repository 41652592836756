import React from "react";
import readerNewsImg from "../../../../public/images/readernews.png";
import "./ReaderNewsForm.css";
import PageHederImage from "../../UI/HeaderImage";

const ReaderNewsForm = () => {
  return (
    <div className="reader-news-form d-flex flex-column global-padding gap-3">
      
      <PageHederImage image={readerNewsImg} text="Läsarnyhet" />


      <div className="fs-1 fw-bold text-uppercase">skicka in läsarnyhet</div>
      <div className="fs-3">
        Skriv en egen nyhet eller en insändare och bidra till att göra
        SävsjöAppen ännu bättre. <br /> <br /> Redaktionen förbehåller sig
        rätten att avgöra om inskickat material ska publiceras och kan vid behov
        redigera texten.
      </div>

    </div>
  );
};

export default ReaderNewsForm;
