import React from "react";
import skorpor from "../../../public/images/skorpor.png";

const AssociationAd = (props) => {
  return (
    <div className={`association-ad d-flex`}>
      <div className="association-ad-body d-flex flex-column">
        <p className="ad-type text-uppercase">{props.header}</p>
        <div className="body">
          <h1
            className={`title fw-bold ${
              props.page === "associationLife" ? "fs-1" : "fs-3"
            }`}
          >
            Vill du vara med och samla in till Ukraina i helgen
          </h1>
          <div className="title-descr d-flex align-items-center gap-3">
            <p
              className={`descr ${
                props.page === "associationLife" ? "fs-2" : "fs-4"
              }`}
            >
              Till helgen är det insamling till Ukraina på olika ställen
              runtomkring, vill du vara med och skänka gåvor till Ukraina,
            </p>
            <img src={skorpor} alt="" className="h-25 w-50" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssociationAd;
