import React from "react";
import { getEvents } from "../../../../../api/Events";

const EventsLogic = () => {
  const [eventsData, setEventsData] = React.useState([]);
  const [loading, setLoading] = React.useState([]);;

  React.useEffect(() => {
    getEvents(setEventsData, setLoading);
  }, []);
  return { eventsData, loading };
};

export default EventsLogic;
