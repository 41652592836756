import React from "react";
import Menu from "./menu/Menu";
import "./Header.css";
import { getHeader } from "../../../api/App";
import { Link } from "react-router-dom";

const Header = () => {

  const [ header , setappData] = React.useState([]);

  React.useEffect(() => {
    getHeader(setappData)
  }, []);

  return (
    <div className="header">
      <Link to="/" className="header-img"><img src={"https://cdn.lokal.app/uploads/" + header} alt="" className="header-img" /></Link>
      <Menu />
    </div>
  );
};

export default Header;
