import React from "react";
import { getTodayData } from "../../../../api/Today";
import idagImg from "../../../../public/images/idag.png";
import { AppContext } from "../../../../context/Data";
import Loading from "../../UI/Loading";
import "./Today.css";
import PageHederImage from "../../UI/HeaderImage";

const Today = () => {
  const [todayData, setTodayData] = React.useState({});
  const { loading, setLoading } = React.useContext(AppContext);

  React.useEffect(() => {
    getTodayData(setTodayData, setLoading);
  }, []);

  let now = new Date();
  const options = { weekday: "long", month: "long", day: "numeric" };
  console.log(now.toLocaleDateString("sv-SV", options));

  return (
    <div className="today">
      
      <PageHederImage image={idagImg} text="Idag" />

      <div className="fs-3 text-uppercase fw-bolder">
        {now.toLocaleDateString("sv-SV", options)}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="day-name">
            <div className="fs-4 fw-bold">Namnsdag</div>
            <div className="fs-5">{todayData.nameday}</div>
          </div>
          <div className="day-theme">
            <div className="fs-4 fw-bold">Temadagar</div>
            <div
              className="fs-5"
              dangerouslySetInnerHTML={{ __html: todayData.themedays }}
            ></div>
          </div>
          <div className="history-events">
            <div className="fs-4 fw-bold">Historiska händelser</div>
            <div
              className="fs-5 w-75"
              dangerouslySetInnerHTML={{ __html: todayData.txt }}
            ></div>
          </div>
        </>
      )}
    </div>
  );
};

export default Today;
