import React from "react";

const ResaDetailsItem = ({
  to,
  time,
  name,
  onClick,
  resaDetailsStop,
  open,
}) => {
  return (
    <div>
      <div className="mot-destination d-flex gap-5" role="button" onClick={onClick}>
        <div className="mot fw-bold fs-4">Mot:</div>
        <div className="destination d-flex flex-column gap-3">
          <h3 className="fw-bold fs-4">{to}</h3>
          <div className="time-train d-flex" >
            <div className="fs-5">Kl. {time}</div>
            <div className="fs-5 resa-details-name ms-4">{name}</div>
            <div className="bg-white fs-4 align-item-end text-uppercase fw-bold ms-5" >
              {"↓"}
            </div>
          </div>
          {open ? <hr className="border border-1 border-dark" /> : null}
          {resaDetailsStop}
        </div>
      </div>
    </div>
  );
};

export default ResaDetailsItem;
