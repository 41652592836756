import React from "react";
import { getNews } from "../../../../../api/News";
import { AppContext } from "../../../../../context/Data";

const NewsLogic = () => {
  const [ NewsData, setNewsData] = React.useState([]);
  const { loading, setLoading } = React.useContext(AppContext);

  React.useEffect(() => {
    getNews(setNewsData, setLoading);
  }, []);
  return { NewsData, loading };
};

export default NewsLogic;
