import React from "react";
import { FiExternalLink } from "react-icons/fi";

const SoldResidenceItem = ({ type, published, price, address, place, url }) => {
  return (
    <div className="free-jobs-item d-flex gap-3">
      <div className="residence-item-body d-flex flex-column justify-content-between">
        <div className="fw-bold fs-4">{type}</div>
        <div className="fs-5">{price}</div>
        <div className="fs-5">
          {address}, {place}
        </div>
        <div className="fs-5">Publicerad : {published}</div>
      </div>
      <div className="residence-item-icon-div ms-auto align-self-end">
        <a href={url} target="_blank" rel="noopener noreferrer">
          <FiExternalLink className="residence-item-icon" />
        </a>
      </div>
    </div>
  );
};

export default SoldResidenceItem;
