import React from "react";
import "./style/Loading.css";

const Loading = () => {
  return (
    // <div className="text-center fs-1 border border-2 border-dark">Laddar...</div>
    <div className="lds-roller align-self-center">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loading;
