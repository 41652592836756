import React from "react";
import familyNewsImg from "../../../../public/images/family.png";
import FamilyNewsItem from "./FamilyNewsItem";
import { useNavigate} from "react-router-dom";
import { AppContext } from "../../../../context/Data";
import { getFamilyNews } from "../../../../api/FamilyNews";
import Loading from "../../UI/Loading";
import "./FamilyNews.css";
import PageHederImage from "../../UI/HeaderImage";

const FamilyNews = () => {
  const [familyNewsData, setFamilyNewsData] = React.useState([]);

  const { loading, setLoading } = React.useContext(AppContext);

  const navigate = useNavigate();

  React.useEffect(() => {
    getFamilyNews(setFamilyNewsData, setLoading);
  }, []);

  return (
    <div className="family-news">

      <PageHederImage image={familyNewsImg} text="Familjenyheter" />
      
      <div className="mt-3">

      {loading ? (
        <Loading />
      ) : (
        familyNewsData?.map((familyNews, i) => (
          <div key={familyNews.id}>
            <FamilyNewsItem
              title={familyNews.title}
              body={familyNews.body}
              src={`https://cdn.lokal.app/uploads/${familyNews.medias?.map(
                (item) => item.src_thmb
              )}`}
              id={familyNews.id}
            />
            <hr className="border border-1 border-dark" />
          </div>
        ))
      )}

    </div>

    </div>
  );
};

export default FamilyNews;
