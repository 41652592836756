import React from "react";
import { useNavigate } from "react-router-dom";
import { getTopNews } from "../../../../../api/TopNews";
import moment from "moment";
import "moment/locale/sv";
import "./MostRead.css";

const MostRead = () => {

  const [ TopNewsData, setTopNewsData] = React.useState([]);
  const [ Loading, setLoading] = React.useState();

  const navigate = useNavigate();

  const navigateToAdOnClick = (id) => {
    navigate(`/news/${id}`);
  }


  React.useEffect(() => {
    getTopNews(setTopNewsData, setLoading);
  }, []);

  return (
    <div className="">
      <div className="text-uppercase fs-5 fw-bold">Mest lästa just nu</div>
      {
        TopNewsData?.map(item => (

          <div className="row gx-2 mt-2" key={'mostread_' + item.id}>
        <div className="col-3 img-max">
          {item.medias?.length > 1 ? (
            <ImageThmbSlider
              slides={item.medias}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              id={item.medias.map((media) => media.id)}
              className="association-ad-slider-imgs"
            />
          ) : (
            <img
              src={`https://cdn.lokal.app/uploads/${item.medias?.map(
                (media) => media.src_thmb
              )}`}
              alt=""
              className={`association-ad-img rounded-3`}
            />
          )}
        </div>
    
            <div className="col-9">
            <div className="fw-bold fs-5 lh-1" role='button'
            key={'read_more' + item.id}
            onClick={() => navigateToAdOnClick(item.id)} >{item.title}</div>
            <div className="fs-6 text-muted">{moment
           .utc(item.timestart)
           .local("sv")
           .startOf("seconds")
           .fromNow()
            }</div>
          </div>
    </div>
        ))}
    </div>
  );
};

export default MostRead;
