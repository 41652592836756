import React from "react";
import residenceImg from "../../../../public/images/vader.jpg";
import WeatherItem from "../../weather/WeatherItem";
import WeatherLogic from "./logic/WeatherLogic";
import Loading from "../../UI/Loading";
import "./Weather.css";
import WeatherHours from "../../weather/WeatherHours";
import PageHederImage from "../../UI/HeaderImage";
const Weather = () => {
  const { places, loading, getPlaceOnClick, weatherData, placeId } = WeatherLogic();

  return (
    <div className="weather">
      
      <PageHederImage image={residenceImg} text="Väder" />

      <div className="weather-city-choice d-flex flex-column gap-3 mb-3">
        <div className="fw-bold text-uppercase fs-5">visa prognos för</div>
        <div className="weather-cities-choice d-flex gap-3">
          {places?.map((place) => (
            <div
              className="fs-6 text-uppercase weather-place"
              role="button"
              onClick={() => getPlaceOnClick(place.id)}
              key={place.id}
            >
              {place.id == placeId ? (
                <div className="fw-bold">{place.name}</div>
              ) : (
                <div>{place.name}</div>
              )}
              
            </div>
          ))}
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          {/* {weatherData?.map((item) => (
            <WeatherHours hours={item.hours} />
          ))} */}

          {weatherData?.map((item) => (

            <>
            <WeatherItem
              key={item.id}
              date={item.date}
              max={item.max}
              min={item.min}
              rain={item.r}
              wind={item.ws}
              windDirection={item.wd}
              icon={`https://api.lokal.app/imgs/${item.icon}`}
              stars={item.stars}
              safety={item.stars_string}
              itemId={item.id}
              weatherData={weatherData}
              hours={item.hours}
            />

            <hr />

            </>

          ))}
        </>
      )}
    </div>
  );
};

export default Weather;
