import React from "react";
import AdvertiserBody from "../../advertiser/AdvertiserBody";
import AdvertiserNameLogo from "../../advertiser/AdvertiserNameLogo";
import AdvertiserDetailsLogic from "./logic/AdvertiserDetailsLogic";

const AdvertiserUnderPage = () => {
  const { advertiserDetailsData } = AdvertiserDetailsLogic();
  return (
    <div className="advertiser-under-page global-padding d-flex flex-column gap-5">
      <AdvertiserNameLogo data={advertiserDetailsData} />
      <AdvertiserBody data={advertiserDetailsData} underpage={true} />
    </div>
  );
};

export default AdvertiserUnderPage;
