import React from "react";
import photo from "../../../../public/images/bilder.png";
import Loading from "../../UI/Loading";
import PhotosLogic from "./logic/PhotosLogic";
import PhotosItem from "./PhotosItem";
import "./Photos.css";
import PageHederImage from "../../UI/HeaderImage";

const Photos = () => {
  const { photosData, loading } = PhotosLogic();
  return (
    <div className="photos">

    <PageHederImage image={photo} text="Bilder" />

    <div className="mt-3">

      {loading ? (
        <Loading />
      ) : (
        <div className="d-flex flex-column gap-5">
          {photosData?.map((item) => (
            <PhotosItem body={item.body} medias={item.medias} key={item.id} />
          ))}
        </div>
      )}
    </div>

    </div>
  );
};

export default Photos;
