import React from "react";
import Map from "../maps/Map";

const AdvertiserAddress = ({ data }) => {
  return (
    <div className="advertiser-details-address d-flex">
      <div className="address-left">
        <h5 className="fw-bold">Besöksadress</h5>
        <h5
          dangerouslySetInnerHTML={{
            __html: data.profile_address,
          }}
        ></h5>
      </div>
      <div className="w-50 ms-auto">
        <Map
          lat={data.profile_latitude}
          long={data.profile_longitude}
          height="200px"
          width=""
          fontsize={"80px"}
        />
      </div>
    </div>
  );
};

export default AdvertiserAddress;
