import React, { useEffect } from "react";
import todayFlowerImg from "../../../../public/images/flower-header-img.png";
import PageHederImage from "../../UI/HeaderImage";
import TodayFlowerForm from "./TodayFlowerForm";


const TodayFlowerFormPage = () => {

  const [sending, setSending] = React.useState(false);

  useEffect(() => {
    setSending(0);
 }, []);

  return (
    <div className="today-flower-form-page">

      <PageHederImage image={todayFlowerImg} text="Dagens ros" />

      <div className="fs-5 mt-1 mb-1">
        Ge Dagens ros till någon som förtjänar extra tack eller uppskattning. <br /> <br />
        Redaktionen förbehåller sig rätten att avgöra om inskickat material ska
        publiceras och kan vid behov redigera texten.
      </div>
      { sending == 1 ? (
      <div class="alert alert-primary fs-5 mt-5" role="alert">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
          Skickar...
      </div>) : null}
      { sending == 2 ? (
      <div class="alert alert-primary fs-5 mt-5" role="alert">Tack för ditt bidrag, vi kommer att behandla det så fort vi kan.</div>) : null}
      { sending == 0 ? (<TodayFlowerForm setSending={setSending} />) : null}
      
    </div>
  );
};

export default TodayFlowerFormPage;
