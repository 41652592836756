import React from "react";
import { AiFillStar } from "react-icons/ai";
import { FiArrowUpCircle } from "react-icons/fi";
import { IconContext } from "react-icons/lib";
import WeatherHours from "../weather/WeatherHours";

const WeatherItem = ({
  date,
  icon,
  max,
  min,
  rain,
  wind,
  windDirection,
  stars,
  safety,
  hours,
}) => {
  // const [id, seId] = React.useState({});
  const [isOpen, setIsOpen] = React.useState(false);

  const options = { weekday: "long", month: "long", day: "numeric" };
  const convertDate = new Date(date).toLocaleDateString("sv-SV", options);

  const showStars = () => {
    let icons = [];
    for (let i = 0; i < stars; i++) {
      icons.push(<AiFillStar key={i} className="fs-5" />);
    }
    return icons;
  };

  return (
    <div className="weather-item d-flex flex-column w-100">
      <div className="fs-4 weather-date fw-bold">{convertDate.toUpperCase()}</div>
      <div className="weather-body d-flex justify-content-between align-items-center">
        <img src={icon} alt="" className="weather-icon" />
        <div className="weather-max-min d-flex flex-column align-items-end ">
          <div className="min fs-6 fw-bold">{min}°C</div>
          <div className="max fs-6">{max}°C</div>
        </div>
        <div className="weather-rain fs-6">{rain.toFixed(2)} mm</div>
        <div className="weather-wind-speed d-flex flex-column align-items-center ">
          <IconContext.Provider
            value={{
              style: {
                transform: `rotate(${windDirection}deg)`,
                // fontSize: "28px",
                fontWeight: "bolder",
              },
            }}
          >
            <FiArrowUpCircle className="wind-icon fs-5" />
          </IconContext.Provider>
          <div className="fs-6">{wind} m/s</div>
        </div>
        <div className="weather-safety d-flex flex-column align-items-center">
          <div className="fs-6">{showStars()}</div>
          <div  className="fs-6">{safety}</div>
        </div>
        <button className="more fs-6" onClick={() => setIsOpen(!isOpen)}>
          Mer...
        </button>
      </div>
      {isOpen && (
        <div className="table-responsive w-100">
          <WeatherHours hours={hours} />
        </div>
      )}
    </div>
  );
};

export default WeatherItem;
