import React from "react";
import freeJobsImg from "../../../../public/images/freejobs.png";
import Map from "../../maps/Map";
import TraficInfoLogic from "./logic/TraficInfoLogic";
import "./TraficInfo.css";
import TraficInfoItem from "./TraficInfoItem";
import Loading from "../../UI/Loading";
import PageHederImage from "../../UI/HeaderImage";

const TraficInfo = () => {
  const { traficInfoData, longitude, latitude, loading } = TraficInfoLogic();

  return (
    <div className="trafic-info d-flex flex-column gap-3">
      <PageHederImage image={freeJobsImg} text="Trafikinfo" />

      <div className="map fs-1">
        <Map data={traficInfoData} long={longitude} lat={latitude} height="500px" fontsize={"100px"} />
      </div>
      <div className="fs-5">
        Trafikinfo från länet angående vägarbete, köer, stillastående fordon och
        mer hämtas från Trafikverket
      </div>
      {loading ? (
        <Loading />
      ) : (
        traficInfoData?.map((item) => (
          <div key={item.id}>
            <TraficInfoItem
              messageCode={item.MessageCode}
              severity={item.SeverityText}
              locationDescription={item.LocationDescriptor}
              message={item.Message}
              startTime={item.StartTime.slice(0, 16)}
              endTime={item.EndTime.slice(0, 16)}
              iconId={item.IconId}
            />
            <hr className="border border-dark" />
          </div>
        ))
      )}
    </div>
  );
};

export default TraficInfo;
