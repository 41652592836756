import React from "react";
import ImageSlider from "./ImageSlider";
import ImageThmbSlider from "./ImageThmbSlider";

const Image = ({ medias, type }) => {
 
  let currentIndex;
  let setCurrentIndex;

  if (!medias) {
    return null;
  }

  if (!Array.isArray(medias)) {

    if (typeof medias === "object")
    {
      medias = [medias];
    }
    else
    {
      if (type != "absolute")
      {
        let item = {}
        item.src = medias.replace("https://cdn.lokal.app/uploads/", "");
        item.src_thmb = medias.replace("https://cdn.lokal.app/uploads/", "");
        medias = [item];
      }


    }

  }

  if (type === "thmb") {
    return (
      <>
      {medias?.length > 1 ? (
        <ImageThmbSlider
          slides={medias}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          id={medias[0].id}
        />
      ) : (
        <div className="img-max text-center">
          <img
            src={`https://cdn.lokal.app/uploads/${medias?.map(
              (media) => media.src_thmb
            )}`}
            alt=""
            className="rounded-3"
          />
        </div>
      )}
      </>
    )
  }
  if (type === "absolute") {
    return (
        <div className="img-max text-center">
          <img
            src={medias}
            alt=""
            className="rounded-3"
          />
        </div>
    )
  }
  else {
    return (
      <>
      {medias?.length > 1 ? (
        <ImageSlider
          slides={medias}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          id={medias[0].id}
        />
      ) : (
        <>
          <img
            src={`https://cdn.lokal.app/uploads/${medias?.map(
              (media) => media.src
            )}`}
            alt=""
            className="rounded-3"
          />
        </>
      )}
      </>
    )
  }
};

export default Image;
