import React from "react";
import loginImg from "../../../../public/images/login.png";
import "./Register.css";
import RegisterForm from "./RegisterForm";

const Register = () => {
  return (
    <div className="register d-flex flex-column gap-3">
      <div className="events-header">
        <img src={loginImg} alt="" className="w-100" />
        <h1 className="fs-1 fw-bold text-uppercase events-header-title text-white">
          Skapa Konto
        </h1>
      </div>
      <h2>Skapa konto för att kunna delta i sidan Debatt</h2>
      <RegisterForm />
    </div>
  );
};

export default Register;
