import React from "react";
import { useNavigate } from "react-router-dom";

const AdvertiserNews = ({ medias, title, body, id, onClick }) => {
  return (
    <div
      className={`advertiser-news d-flex flex-column gap-3 col-6`}
      onClick={onClick}
    >
      <div className="">
        {medias?.length > 1 ? (
          <ImageSlider
            slides={medias}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            id={id}
          />
        ) : (
          <img
            src={`https://cdn.lokal.app/uploads/${medias?.map(
              (media) => media.src
            )}`}
            alt=""
            className="advertiser-news-img"
          />
        )}
      </div>
      <div className="d-flex flex-column">
        <div className="fs-2 fw-bolder advertiser-news-title">{title}</div>
        <div
          className="fs-5 advertiser-news-body"
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
      </div>
    </div>
  );
};

export default AdvertiserNews;
