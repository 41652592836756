import React from "react";
import ImageSlider from "../UI/ImageSlider";

const AdvertiserAd = ({
  data,
  currentIndex,
  setCurrentIndex,
  typeOf,
  place,
  date,
  time,
}) => {
  if (typeOf === "object") {
    return (
      <div
        className="advertiser-details-ad d-flex flex-column gap-3"
        key={data.id}
      >
        {data.medias?.length > 1 ? (
          <ImageSlider
            slides={data.medias}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            id={data.id}
          />
        ) : (
          <>
            <img
              src={`https://cdn.lokal.app/uploads/${data.medias?.map(
                (media) => media.src
              )}`}
              alt=""
              className="advertiser-details-ad-img w-100"
            />
            {data.medias?.map(
              (media) =>
                media.description?.length > 0 && (
                  <div className="fs-4 fst-italic " key={media.id}>
                    {media.description}
                  </div>
                )
            )}
          </>
        )}

        <div className="fs-2 fw-bolder lh-sm">
          {data.title}
        </div>
        {(place === "readernewsdetails" || place === "newsdetails") && (
          <div className="fs-5 text-muted">
            {date} {time}
          </div>
        )}
        <div
          className="fs-5"
          dangerouslySetInnerHTML={{ __html: data.body }}
        ></div>
      </div>
    );
  }

  return data?.map((ad) => (
    <div className="advertiser-details-ad d-flex flex-column gap-3" key={ad.id}>
      {ad.medias?.length > 1 ? (
        <ImageSlider
          slides={ad.medias}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          id={ad.id}
        />
      ) : (
        <img
          src={`https://cdn.lokal.app/uploads/${ad.medias?.map(
            (media) => media.src
          )}`}
          alt=""
          className="advertiser-details-ad-img"
        />
      )}

      <div className="advertiser-details-ad-title fs-3 fw-bold">{ad.title}</div>
      <div
        className="advertiser-details-ad-body fs-5"
        dangerouslySetInnerHTML={{ __html: ad.body }}
      ></div>
    </div>
  ));
};

export default AdvertiserAd;
