import React from "react";
import "./Happening.css";
import HappeningEvents from "./HappeningEvents";
import EventsLogic from "../../../pages/events/logic/EventsLogic";


const Happening = () => {

  const { eventsData, loading } = EventsLogic();

  return (

<>

      {loading ? (
        null
      ) : (
        <div className="">
        <div className="fw-bold text-uppercase fs-5">Kommande händelser</div>
      { eventsData?.slice(0, 3).map((item) => (
      <HappeningEvents
        image={`https://cdn.lokal.app/uploads/${item.media?.src_thmb}`}
        title={item.title}
        datetime={item.date + " " + item.starttime}
        place={item.place}
        key={item.id}
        id={item.id}
        />
      
      ))}
      </div>
      )}

</>

  );
};

export default Happening;
