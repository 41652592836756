import axios from "axios";
import { getAppId } from "./getAppId";

export const getAdvertiserDetails = async (
  setAdvertiserDetailsData,
  setLoading,
  id
) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: `https://cdn.lokal.app/api/customerforweb/${id}?app_id=` + getAppId(),
  })
    .then((res) => {
      setAdvertiserDetailsData(res.data);
      setLoading(false);
      console.log(res.data);
    })
    .catch((e) => {
      console.log(e);
    });
};
