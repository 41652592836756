import React from "react";
import { getCordinates } from "../../../../../api/App";
import { getSoldResidence } from "../../../../../api/Residence";


const SoldResidenceLogic = () => {
  const [soldResidenceData, setSoldResidenceData] = React.useState([]);
  const [ loading, setLoading ] = React.useState([]);
  const [ longitude, setLongitude] = React.useState([]);
  const [ latitude, setLatitude] = React.useState([]);

  React.useEffect(() => {
    getSoldResidence(setSoldResidenceData, setLoading);
    getCordinates(setLongitude, setLatitude);
  }, []);
  return {soldResidenceData, longitude, latitude, loading};
};

export default SoldResidenceLogic;
