import React from "react";
import moment from "moment";
import "moment/locale/sv";

const AdvertiserNewsSide = ({ medias, title, body, id, onClick, date }) => {
  const dateToDisplay = moment
    .utc(date)
    .local("sv")
    .startOf("seconds")
    .fromNow();

  return (
    <div className="advertiser-headline d-flex flex-column" onClick={onClick}>
      {medias?.length > 1 ? (
        <ImageSlider
          slides={medias}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          id={id}
        />
      ) : (
        <img
          src={`https://cdn.lokal.app/uploads/${medias?.map(
            (media) => media.src
          )}`}
          alt=""
          className="advertiser-headline-img"
        />
      )}
      <div className="fs-4 fw-superbold lh-sm mb-2">{title}</div>
      <div
        className="fs-5 lh-sm lines-4"
        dangerouslySetInnerHTML={{ __html: body }}
      ></div>
      <div className="text-muted fs-6 fst-italic">{dateToDisplay}</div>
    </div>
  );
};

export default AdvertiserNewsSide;
