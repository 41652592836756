import React from "react";
import "./LeftBar.css";
import {Adsense} from '@ctrl/react-adsense';
import { useLocation } from "react-router-dom";


const LeftBar = () => {

  const location = useLocation();

  const [adKey, setAdKey] =  React.useState([]);
  const [adKey2, setAdKey2] =  React.useState([]);

  React.useEffect(() => {
    setAdKey(Math.random());
    setAdKey2(Math.random());
  }, [location]);

  return (<div className="left-bar">
  <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>

<div className="mb-3">
  <Adsense
    key={adKey}
    client="ca-pub-8986694224901758"
    slot="5062349326"
  />
</div>
<div className="mb-3">
<Adsense
    key={adKey2}
    client="ca-pub-8986694224901758"
    slot="1460943837"
  />
</div>

  </div>);
};

export default LeftBar;
