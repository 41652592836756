import React from "react";
import resaImg from "../../../../public/images/resa.png";
import ResaDetailsLogic from "./logic/ResaDetailsLogic";
import "./ResaDetails.css";
import ResaDetailsItem from "./ResaDetailsItem";
import ResaDetailsStops from "./ResaDetailsStops";
import Loading from "../../UI/Loading";
import PageHederImage from "../../UI/HeaderImage";

const ResaDetails = () => {
  const { open, travelDetailsData, handleClick, loading } = ResaDetailsLogic();

  // console.log(travelDetailsData);

  return (
    <div className="resa-details">
      
      <PageHederImage image={resaImg} text="Resa" />


      <div className="from d-flex gap-3 align-items-center">
        <div className="fw-bold fs-5">Från : </div>
        <div className="fw-bold fs-5">{travelDetailsData.from}</div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        travelDetailsData.departures?.map((item) => (
          <div key={item.id}>
            <ResaDetailsItem
              to={item.to}
              name={item.name}
              time={item.time.slice(0, 5)}
              onClick={() => handleClick(item.id)}
              open={open[item.id]}
              resaDetailsStop={
                open[item.id]
                  ? item.stops?.map((item) => (
                      <ResaDetailsStops
                        key={item.id}
                        stopsName={item.name}
                        stopsTime={item.time.slice(0, 5)}
                      />
                    ))
                  : null
              }
            />
            <hr className="border border-1 border-dark" />
          </div>
        ))
      )}
    </div>
  );
};

export default ResaDetails;
