import axios from "axios";
import { getAppId } from "./getAppId";

export const getFreeJobs = async (setFreeJobsData, setLoading) => {
    setLoading(true);

    return await axios({
      method: "get",
      url: "https://api.lokal.app/api/workads?app_id=" + getAppId(),
    })
    .then((res) => {
        setFreeJobsData(res.data);
        setLoading(false);
        console.log(res.data);
    })
    .catch((e) => {
        console.log(e)
    });
}