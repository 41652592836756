import React from "react";
import deadAdsImg from "../../../../public/images/deadads.png";
import DeadAdsLogic from "./logic/DeadAdsLogic";
import Loading from "../../UI/Loading";
import "./DeadAds.css";
import PageHederImage from "../../UI/HeaderImage";
import { FiExternalLink } from "react-icons/fi";
import PortalHelmet from "../../UI/PortalHelmet";
import { useLocation } from "react-router-dom";
import ExternalAd from "../../UI/externalAd/externalAd";
import { Adsense } from "@ctrl/react-adsense";

const DeadAds = () => {
  const { deadAdsData, loading } = DeadAdsLogic();

  const location = useLocation();

  const [adKey, setAdKey] =  React.useState([]);
  const [adKey2, setAdKey2] =  React.useState([]);

  React.useEffect(() => {
    setAdKey(Math.random());
    setAdKey2(Math.random());
  }, [location]);

  return (
    <div className="dead-ads">
      <PageHederImage image={deadAdsImg} text="Dödsannonser" />
      <PortalHelmet title="Dödsannonser" />

      {loading ? (
        <Loading />
      ) : (
        deadAdsData?.map((item, index) => (

          <>

          { index == 2 && (<ExternalAd format="middle" />)}
          { index == 8 && (<ExternalAd format="middle" />)}
          { index == 16 && (<ExternalAd format="middle" />)}
          { index == 5 && (<div className="mb-3">
                            <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
                              <Adsense
                                key={adKey}
                                client="ca-pub-8986694224901758"
                                slot="6208982837"
                              />
                            </div>)}
          { index == 12 && (<div className="mb-3">
              <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
              <Adsense
                  key={adKey2}
                  client="ca-pub-8986694224901758"
                  slot="4157534569"
                />
              </div>)}

          <div className="row" key="death_{index}">
            <div className="col-11">
              <div className="fs-5 fw-bold mt-3">{item.name}</div>
              <div className="fs-5">Födelsedaum: {item.birthDate}</div>
              <div className="fs-5">Dödsdatum: {item.deathDate}</div>
            </div>
            <div className="col-1 align-self-end">

            <a href={item.ext_url} target="_blank">
              <FiExternalLink className="dead-ads-item-icon" />
            </a>

            </div>
          
          </div>

          <hr className="border border-1 border-dark clear_both" />


          </>
        ))
      )}
    </div>
  );
};

export default DeadAds;
