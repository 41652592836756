import React from "react";
import { getPlaces, getWeatherData } from "../../../../../api/Weather";
import { AppContext } from "../../../../../context/Data";

const WeatherLogic = () => {
  const [places, setPlaces] = React.useState([]);
  const [placeId, setPlaceId] = React.useState(1);
  const [weatherData, setWeatherData] = React.useState([]);
  const { loading, setLoading } = React.useContext(AppContext);
  const [id, seId] = React.useState({});
  const [isOpen, setIsOpen] = React.useState(false);

  

  const getPlaceOnClick = (id) => {
    let placeName = places.find((x) => x.id === id).id;
    setPlaceId(placeName);
  };

  React.useEffect(() => {
    getPlaces(setPlaces, setLoading);
    getWeatherData(setWeatherData, setLoading, placeId);
  }, [placeId]);
  return { places, loading, getPlaceOnClick, weatherData, placeId };
};

export default WeatherLogic;
