import axios from "axios";
import { getAppId } from "./getAppId";


export const getAppData = async (setData, setLoader) => {
  setLoader(true);
  return await axios({
    method: "get",
    url: "https://cdn.lokal.app/api/app/" + getAppId(),
  })
    .then((res) => {
      setData(res.data);
      setLoader(false);
      console.log(res.data);
    })
    .catch((e) => {
      console.log(e);
      setLoader(false);
    });
};

export const redirectAppDownload = async () => {

  return await axios({
    method: "get",
    url: "https://cdn.lokal.app/api/app/" + getAppId(),
  })
    .then((res) => {

      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let platform = null;
  
      if (/android/i.test(userAgent)) {
        platform = "Android";
      }
    
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        platform = "iOS";
      }
    
      if (platform) {
      
        if (platform == "Android")
        {
          location.href = res.data.googleplay_link;
        }
        else if (platform == "iOS")
        {
          location.href = res.data.itunes_link;
        }
    
      }

    })
    .catch((e) => {

    });

}

export const getHeader = async (setHeader) => {
  return await axios({
    method: "get",
    url: "https://cdn.lokal.app/api/app/" + getAppId(),
  })
    .then((res) => {
      setHeader(res.data.headerimage);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getName = async (setName) => {
  return await axios({
    method: "get",
    url: "https://cdn.lokal.app/api/app/" + getAppId(),
  })
    .then((res) => {
      setName(res.data.name);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getShortName = async (setName) => {
  return await axios({
    method: "get",
    url: "https://cdn.lokal.app/api/app/" + getAppId(),
  })
    .then((res) => {
      setName(res.data.shortName);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getCordinates = async (setLongitude, setLatitude) => {
  return await axios({
    method: "get",
    url: "https://cdn.lokal.app/api/app/" + getAppId(),
  })
    .then((res) => {
      setLongitude(res.data.places[0].longitude);
      setLatitude(res.data.places[0].latitude);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getDomainToByline = async (byline, setDomain) => {
  return await axios({
    method: "get",
    url: "https://cdn.lokal.app/api/app/" + getAppId(),
  })
    .then((res) => {

      let result = byline;

      if (byline)
      {
       result = byline.replace(/#domain#/g, res.data.domain);
      }
      
      setDomain(result);
    
    })
    .catch((e) => {
      console.log(e);
    });
};

