import React from "react";
import "./Events.css";
import eventHeaderImg from "../../../../public/images/event-header-img.png";
import EventsLogic from "./logic/EventsLogic";
import Loading from "../../UI/Loading";
import PageHederImage from "../../UI/HeaderImage";
import Image from "../../UI/Image";
import moment from "moment";
import "moment/locale/sv";
import { useNavigate } from "react-router-dom";
import PortalHelmet from "../../UI/PortalHelmet";
import ExternalAd from "../../UI/externalAd/externalAd";
import { Adsense } from "@ctrl/react-adsense";


const Events = () => {
  const { eventsData, loading } = EventsLogic();

  const navigate = useNavigate();

  const [adKey, setAdKey] =  React.useState([]);
  const [adKey2, setAdKey2] =  React.useState([]);
  const [adKey3, setAdKey3] =  React.useState([]);

  React.useEffect(() => {
    setAdKey(Math.random());
    setAdKey2(Math.random());
    setAdKey3(Math.random());
  }, []);

  const navigateOnClick = (id) => {
    navigate(`/events/${id}`);
  }


  return (
    <div className="events">

      <PageHederImage image={eventHeaderImg} text="Evenemang" />
      <PortalHelmet title="Evenemang" />

      {loading ? (
        <Loading />
      ) : (
        eventsData?.map((item, index) => (
          <>
          { index == 2 && (<div className="mb-3">
                            <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
                              <Adsense
                                key={adKey}
                                client="ca-pub-8986694224901758"
                                slot="1633696971"
                                style={{ display: "block" }}
                                format="fluid"
                                layoutKey="-fg-1b+8p-kn+fh"
                              />
                            </div>)}
          { index == 5 && (<div className="mb-3">
                            <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
                              <Adsense
                                key={adKey2}
                                client="ca-pub-8986694224901758"
                                slot="5750299368"
                                style={{ display: "block" }}
                                format="fluid"
                                layoutKey="-6t+ed+2i-1n-4w"
                              />
                            </div>)}
          { index == 8 && (<div className="mb-3">
                            <div className="text-uppercase fs-6 text-muted ms-1 mb-1 mt-1">Annons</div>
                              <Adsense
                                key={adKey3}
                                client="ca-pub-8986694224901758"
                                slot="6747805746"
                                style={{ display: "block" }}
                                format="fluid"
                                layoutKey="-ep-2o+n4-lt-zj"
                              />
                            </div>)}

          <div key={'event_ ' + item.id} onClick={() => navigateOnClick(item.id)}>

            <div className="fw-bold fs-3 mt-3">{item.title}</div>

            <div className="row">
              <div className="col-9">

                <div className="fs-6 mb-3 event-descr">{item.txt}</div>
                <div className="fw-bold fs-6 text-uppercase">{moment.utc(item.date).local("sv").format("dddd D MMMM")} {item.starttime}</div>
                <div className="fw-bold fs-6 text-uppercase">Plats: {item.place}</div>
                
              </div>
              <div className="col-3">
                  <Image medias={item.media} type="thmb" />
                  <div className="fw-bold fs-6 text-uppercase text-end mt-3" role="button">Läs mer {'>'}</div>
              </div>
              
            </div>
            <hr className="border border-1 border-dark" />
          </div>

          </>
        ))
      )}
    </div>
  );
};

export default Events;
