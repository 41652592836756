import React from "react";
import policeImg from "../../../../public/images/police.png";
import PoliceNewsLogic from "./logic/PoliceNewsLogic";
import "./PoliceNews.css";
import PoliceNewsItem from "./PoliceNewsItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { getPoliceNews } from "../../../../api/PoliceNews";
import Loading from "../../UI/Loading";
import PageHederImage from "../../UI/HeaderImage";
import { FiExternalLink } from "react-icons/fi";

const PoliceNews = () => {
  const { policeNewsData, setPoliceNewsData, loading, setLoading } =
    PoliceNewsLogic();

  return (
    <div className="police-news">
      
      <PageHederImage image={policeImg} text="Polisnyheter" />

      {loading ? (
        <Loading />
      ) : (
        <>
          {policeNewsData?.map((item, index) => (

          <>
          <div className="row" key="police_{index}">
            <div className="col-11">
              <div className="fs-5 fw-bold mt-3">{item.title}</div>
              <div className="fs-5">{item.content}</div>
            </div>
            <div className="col-1 align-self-end">

            <a href={item.url} target="_blank">
              <FiExternalLink className="police-news-item-icon" />
            </a>

            </div>
          
          </div>

          <hr className="border border-1 border-dark clear_both" />
          
          </>

          ))}
          </>
      )}
    </div>
  );
};

export default PoliceNews;
