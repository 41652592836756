import axios from "axios";
import { getAppId } from "./getAppId";

export const getPoliceNews = async (setPoliceNewsData, setLoading) => {
  setLoading(true);
  return await axios({
    method: "get",
    url: "https://api.lokal.app/api/police?app_id=" + getAppId(), 
  })
    .then((res) => {
      setPoliceNewsData(res.data);
      setLoading(false);
      console.log(res.data);
    })
    .catch((e) => {
      console.log(e);
    });
};
