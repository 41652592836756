import axios from "axios";
import React, { useEffect } from "react";
import { getAppId } from "../../../../api/getAppId";

const QuizForm = (parent) => {

  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedImagesBlob, setSelectedImagesBlob] = React.useState(null);

  const [frm_name, setName] = React.useState("");
  const [frm_email, setEmail] = React.useState("");
  const [frm_mobileNumber, setMobileNumber] = React.useState("");
  const [frm_message, setMessage] = React.useState("");
  const [frm_answer1, setAnswer1] = React.useState("");
  const [frm_answer2, setAnswer2] = React.useState("");
  const [frm_answer3, setAnswer3] = React.useState("");
  const [frm_answer4, setAnswer4] = React.useState("");
  const [frm_correct, setCorrect] = React.useState("");
  
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const today = new Date();
  const date = today.setDate(today.getDate() + 3); 

  React.useEffect(() => {
    setSelectedImagesBlob([]);
  }, []);

 const convertImage = (file) => {

 let reader = new FileReader();
 reader.onload = function(e) {

  let blob = e.target.result;


  selectedImagesBlob.push(blob);

   setSelectedImagesBlob(selectedImagesBlob);

   forceUpdate();

  document.getElementById("myImage").value = null;

 };
 reader.readAsDataURL(file);

}

const removeImage = (index) => {

  selectedImagesBlob.splice(index, 1);
  setSelectedImagesBlob(selectedImagesBlob);

}

  const postForm = () => {

    let postObject = {
      type: "web_quizquestion",
      name: frm_name,
      email: frm_email,
      phone: frm_mobileNumber,
      question: frm_message,
      answer1: frm_answer1,
      answer2: frm_answer2,
      answer3: frm_answer3,
      answer4: frm_answer4,
      correct: frm_correct,
      imageblobs: selectedImagesBlob,
      app_id: getAppId()
    }

    console.log(postObject);

    if (frm_message == "" || frm_answer1 == "" || frm_answer2 == "" || frm_answer3 == "" || frm_answer4 == "" || frm_correct == "") {
      return 
    }

    parent.setSending(1);

    return axios({
      method: "post",
      url: "https://api.lokal.app/api/postform",
      data: postObject
    })
      .then((res) => {

        parent.setSending(2);

      })


  }
  

  return (
    <div className="event-form">
    <form className="">
      
      <div className="fs-5 fw-bold">1. Fråga</div>

      <div className="mt-3 fs-6 fw-bold">Fråga</div>

      <div className="">
        <textarea
          rows="3"
          className="form-control form-input"
          placeholder="Text"
          required
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>

      <div className="mt-3 fs-6 fw-bold">Svarsalternativ 1</div>
      <input type="text" className="form-control form-input" placeholder="Alternativ 1" required onChange={(e) => setAnswer1(e.target.value)} />

      <div className="mt-3 fs-6 fw-bold">Svarsalternativ 2</div>
      <input type="text" className="form-control form-input" placeholder="Alternativ 2" required onChange={(e) => setAnswer2(e.target.value)} />

      <div className="mt-3 fs-6 fw-bold">Svarsalternativ 3</div>
      <input type="text" className="form-control form-input" placeholder="Alternativ 3" required onChange={(e) => setAnswer3(e.target.value)} />

      <div className="mt-3 fs-6 fw-bold">Svarsalternativ 4</div>
      <input type="text" className="form-control form-input" placeholder="Alternativ 4" required onChange={(e) => setAnswer4(e.target.value)} />


      <div className="mt-3 fs-6 fw-bold">Rätt svar</div>

      <select className="form-control" required onChange={(e) => setCorrect(e.target.value)}>
          <option value="">--Välj vilket alternativ som är rätt--</option>
          <option value="1">Alternativ 1</option>
          <option value="2">Alternativ 2</option>
          <option value="3">Alternativ 3</option>
          <option value="4">Alternativ 4</option>

      </select>

<br></br>

      <div className="">
        <div className="fs-5 fw-bold">2. Eventuella bilder</div>
        <div>Kom ihåg att bara skicka in bilder som du har rätt att publicera.</div>
        
        {selectedImagesBlob == null || selectedImagesBlob.length == 0 ? ( null ) : (

          selectedImagesBlob.map((image, index) => (
    
          (image == null) ? ( null ) : (
          <div ket={'img' + index}>
          <img alt="not fount" width={"250px"} src={image} /><br />
          <button className="btn form-control btn-secondary mt-3" onClick={() => { removeImage(index) }}>Radera bilden</button>
          </div>        
         ) )))}  

      </div>
      <div className="mb-3">
      <input
      type="file"
      name="myImage"
      id="myImage"
      className="form-control mt-3"
      accept="image/jpeg, image/jpg"
      onChange={(event) => {
        console.log(event.target.files[0]);
        setSelectedImage(event.target.files[0]);
        convertImage(event.target.files[0]);
      }}
    />
      </div>
      
      <label className="form-label fs-5 fw-bold mt-5">
        Inskickat av (Publiceras ej)
      </label>
      <div className="mb-1">
        <input
          type="text"
          className="form-control form-input"
          id="fullname"
          placeholder="För- och efternamn"
          required
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="mb-1">
        <input
          type="email"
          className="form-control form-input"
          id="email"
          placeholder="E-postadress"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mb-1">
        <input
          type="tel"
          className="form-control form-input"
          id="tel"
          placeholder="Telefonnummer"
          required
          onChange={(e) => setMobileNumber(e.target.value)}
        />
      </div>
      <button
        className="btn form-control btn-secondary mt-3"
        type="button"
        onClick={postForm}
      >
        Skicka
      </button>
    </form>
  </div>
  );

  
};

export default QuizForm;
