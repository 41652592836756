import React from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../UI/Image";

const FamilyNewsItem = ({ title, body, src, details, id }) => {
  const navigate = useNavigate();
  if (details == true) {
    return (
      <div className="family-news-item-details d-flex flex-column gap-3">
        <img src={src} alt="" className="w-100" />
        <div className="fw-bold text-uppercase fs-5">{title}</div>
        <div
          className="fs-5 event-descr"
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>

        <hr className="border border-1 border-dark" />
      </div>
    );
  }
  return (
    <div
      className="family-news-item"
      onClick={() => navigate(`/familynews/${id}`)}
    >
      <div className="row">
        <div className="col-9">

        <div className="fw-bold fs-5">{title}</div>
        <div
          className="fs-5"
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>

        </div>
        <div className="col-3">
            <Image medias={src} type="thmb" />
        </div>
      </div>

    </div>
  );
};

export default FamilyNewsItem;
