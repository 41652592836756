import React from "react";
import { useNavigate } from "react-router-dom";

const AdvertiserItem = ({ name, logo, id }) => {
  const navigate = useNavigate();
  return (
    <div
      className="advertiser-item d-flex align-items-center"
      onClick={() => navigate(`/advertiser/${id}`)}
    >
      <div className="advertiser-name fs-5 fw-bold w-75">{name}</div>

      <div className="advertiser-img ms-auto text-end">
        <img src={logo} alt="" className="advertiser-item-img" />
      </div>
    </div>
  );
};

export default AdvertiserItem;
