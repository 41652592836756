import React from "react";
import { AiFillStar } from "react-icons/ai";
import WeatherSource from "./WeatherSource";

const WeatherHour = ({ hour, onClick }) => {
  const showStars = () => {
    let icons = [];
    for (let i = 0; i < hour.medel.stars; i++) {
      icons.push(<AiFillStar key={i} className="fs-5" />);
    }
    return icons;
  };

  return (
    <tr className="weather-hour text-center" onClick={onClick}>
      <td valign="middle" className="text center fs-6" scope="row">
        {hour.time}
      </td>
      <td valign="middle" className="text center fs-6">
        <img
          src={`https://api.lokal.app/imgs/${hour.medel.type}.png`}
          alt=""
          className="weather-icon"
        />
      </td>
      <td valign="middle" className="text center temp fs-6">
        {hour.medel.t}°C
      </td>
      <td valign="middle" className="text center  fs-6">
        {hour.medel.r.toFixed(2)} mm
      </td>
      <td valign="middle" className="text center  fs-6">
        {hour.medel.ws} m/s
      </td>
      <td valign="middle" className="weather-safety  fs-6">
        <div className="">{showStars()}</div>
        <div className="">{hour.medel.stars_string}</div>
      </td>
    </tr>
  );
};

export default WeatherHour;
