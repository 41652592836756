import React from "react";
import { getHomeFeed } from "../../../../../api/Home";
import { AppContext } from "../../../../../context/Data";

const HomeLogic = () => {
  const [loading, setLoading] = React.useState([]);
  const [firstNews, setFirstnews] = React.useState([]);
  const [firstCompanyAd, setFirstCompanyAd] = React.useState([]);
  const [firstAssociationAd, setFirstAssociationAd] = React.useState([]);
  const [feed, setFeed] = React.useState([]);

  React.useEffect(() => {
    getHomeFeed(
      setFirstnews,
      setFirstCompanyAd,
      setFirstAssociationAd,
      setFeed,
      setLoading
    );
  }, []);

  return { loading, firstNews, firstCompanyAd, firstAssociationAd, feed };
};

export default HomeLogic;
