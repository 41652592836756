import React from "react";
import AdvertiserAddress from "../../advertiser/AdvertiserAddress";
import AdvertiserBody from "../../advertiser/AdvertiserBody";
import AdvertiserContact from "../../advertiser/AdvertiserContact";
import AdvertiserNameLogo from "../../advertiser/AdvertiserNameLogo";
import AdvertiserOpening from "../../advertiser/AdvertiserOpening";
import AdvertiserPages from "../../advertiser/AdvertiserPages";
import Loading from "../../UI/Loading";
import "./AdvertiserDetails.css";
import AdvertiserDetailsLogic from "./logic/AdvertiserDetailsLogic";
import ImageSlider from "../../UI/ImageSlider";
import AdvertiserAd from "../../advertiser/AdvertiserAd";
import { getShareImg } from "../../../../api/AdDetails";

/* 
name and logo
picture
text
öppetider
besökdress
ring, skicka epost
ring, ring
ring, skicka epost
kurser, produkter
facebook, hemsida
*/

const AdvertiserDetails = () => {
  const { advertiserDetailsData, loading, currentIndex, setCurrentIndex } =
    AdvertiserDetailsLogic();
  const contactsLength = advertiserDetailsData?.contacts?.length;

  return (
    <div className="advertiser-details d-flex flex-column gap-5 global-padding ad-page">
      {loading ? (
        <Loading />
      ) : (
        <>


          <AdvertiserNameLogo data={advertiserDetailsData} />

          <AdvertiserBody data={advertiserDetailsData} />

          {advertiserDetailsData.profile_openhours?.length > 0 ? (
            <AdvertiserOpening data={advertiserDetailsData} />
          ) : null}

          {advertiserDetailsData.profile_address?.length > 0 ? (
            <AdvertiserAddress data={advertiserDetailsData} />
          ) : null}

          {advertiserDetailsData.profile_phone != null ||
          advertiserDetailsData.profile_email != null ? (
            <AdvertiserContact data={advertiserDetailsData} />
          ) : null}

          {contactsLength > 0 ? (
            <AdvertiserContact
              data={advertiserDetailsData}
              contactPersons="contactPersons"
            />
          ) : null}

          {advertiserDetailsData.profile_webb != null ||
          advertiserDetailsData.pages?.length > 0 ? (
            <AdvertiserPages data={advertiserDetailsData} />
          ) : null}

          {advertiserDetailsData.ads?.length > 0 ? (
            <AdvertiserAd
              data={advertiserDetailsData.ads}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default AdvertiserDetails;
